import { normalize } from "normalizr";
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { NormalizrResult, parseAPIRoutineData } from "./Tools";
import { routine, task, child } from "models/Schema";
import TaskContext from "./TaskContext";
import { TasksToValidate, useChildContext } from "./ChildContext";
import RoutineContext from "./RoutineContext";
import FirebaseAPI from "@neurosolutionsgroup/api-client";
import { TaskHistory } from "@neurosolutionsgroup/models";

export interface AppDataContextData {
  dataFetched: boolean;
}

const AppDataContext = React.createContext<AppDataContextData>({
  dataFetched: false,
});

export const AppDataProvider = (props: PropsWithChildren): JSX.Element => {
  const [dataFetched, setDataFetched] = useState(false);

  const { setTasksById } = useContext(TaskContext);
  const { setRoutineIds, setRoutinesById } = useContext(RoutineContext);
  const {
    setChildIds,
    setChildrenById,
    setSelectedChildId,
    setTasksToValidate,
  } = useChildContext();

  useEffect(() => {
    Promise.all([
      FirebaseAPI.Child.getGamerChildren(),
      FirebaseAPI.Routine.getRoutines(),
    ]).then((response) => {
      const rawData = {
        children: response[0].map((c) => {
          return {
            ...c,
            tasksToValidate: c.history
              .filter((task: TaskHistory) =>
                task !== null ? task.confirmTime === null : false
              )
              .sort((a: TaskHistory, b: TaskHistory) =>
                a.dueTime > b.dueTime ? 1 : -1
              ),
          };
        }),
        routines: parseAPIRoutineData(response[1]),
      };

      //  Normalize (flatten) data received.
      const data = normalize(rawData, {
        children: [child],
        tasks: [task],
        routines: [routine],
      }) as NormalizrResult;

      setTasksById(data.entities.tasks);

      setRoutineIds(data.result.routines);
      setRoutinesById(data.entities.routines);

      setChildIds(data.result.children);
      setChildrenById(data.entities.children);

      const tasksToValidate: TasksToValidate = {};

      if (data.entities.children) {
        Object.entries(data.entities.children).forEach(([key, value]) => {
          tasksToValidate[key] = value.history
            ? value.history
                .filter((h) => h.confirmTime === null)
                .sort((a, b) => b.dueTime - a.dueTime)
                .map((h) => ({ ...h, validated: false }))
            : [];
        });

        setTasksToValidate(tasksToValidate);
      } else {
        setTasksToValidate({});
      }

      if (data.result.children.length > 0) {
        setSelectedChildId(data.result.children[0]);
      }

      setDataFetched(true);
    });
  }, []);

  return (
    <AppDataContext.Provider value={{ dataFetched }}>
      {props.children}
    </AppDataContext.Provider>
  );
};

export default AppDataContext;
