import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { BevelButton, TextInput } from "components/inputs";

interface Props {
  isSubmitEnabled: boolean;
  onSubmit: (firstName: string, lastName: string, city: string) => void;
}

const CompleteRegistrationFormComponent = ({
  isSubmitEnabled,
  onSubmit,
}: Props): JSX.Element => {
  const { t } = useTranslation(["webappAuthentication"]);

  const blockName = "complete-registration";

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        city: "",
      }}
      validationSchema={Yup.object({
        firstName: Yup.string()
          .min(3, t("fields.firstName.errors.length"))
          .max(40, t("fields.firstName.errors.length"))
          .required(t("fields.generic.errors.required")),
        lastName: Yup.string()
          .min(3, t("fields.lastName.errors.length"))
          .max(40, t("fields.lastName.errors.length"))
          .required(t("fields.generic.errors.required")),
        city: Yup.string()
          .min(2, t("fields.city.errors.length"))
          .max(50, t("fields.city.errors.length"))
          .required(t("fields.generic.errors.required")),
      })}
      onSubmit={async (values) => {
        onSubmit(values.firstName, values.lastName, values.city);
      }}
    >
      <Form className={blockName + "__form"}>
        <TextInput
          label={t("fields.firstName.label")}
          name="firstName"
          type="text"
        />
        <TextInput
          label={t("fields.lastName.label")}
          name="lastName"
          type="text"
        />
        <TextInput label={t("fields.city.label")} name="city" type="text" />
        <div className={blockName + "__button-group"}>
          <BevelButton
            className="btn btn-primary w-100"
            type="submit"
            disabled={!isSubmitEnabled}
            onClick={() => true}
          >
            {t("pages.completeRegistration.submit")}
          </BevelButton>
        </div>
      </Form>
    </Formik>
  );
};
export default CompleteRegistrationFormComponent;
