import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./App.scss";
import "react-notifications-component/dist/theme.css";

import { useTranslation } from "react-i18next";
import ReactNotification from "react-notifications-component";

// Features.
import { SuspenseLoader } from "components/graphics/SuspenseLoader";
import { Elements } from "@stripe/react-stripe-js";
import { StripeProvider } from "hooks/billing/StripeContext";
import { loadStripe } from "@stripe/stripe-js";
import AppRouter from "AppRouter";
import { getAuth } from "firebase/auth";
import { AuthProvider } from "services/authentication";
import {
  ProjectType,
  RemoteConfigProvider,
} from "@neurosolutionsgroup/remote-config";
import { getApp } from "firebase/app";
import { LanguageProvider } from "hooks/useLanguage";
import {
  AnalyticService,
  AnalyticsProvider,
  AnalyticsServices,
} from "@neurosolutionsgroup/analytics";
import { getMixpanelService } from "@neurosolutionsgroup/mixpanel-analytics";
import { getCustomerIOSDKService } from "@neurosolutionsgroup/customerio-client-sdk";

function Component() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    document.title = t("title");
  }, []);

  let stripePromise = null;

  if (process.env.REACT_APP_STRIPE_PUBLICKEY) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLICKEY);
  }

  const auth = getAuth();

  const app = getApp();

  const services: AnalyticService[] = [];

  if (process.env.NODE_ENV === "development") {
    services.push(AnalyticsServices.ConsoleService);
  } else {
    if (process.env.REACT_APP_MIXPANEL_TOKEN) {
      services.push(getMixpanelService(process.env.REACT_APP_MIXPANEL_TOKEN));
    } else {
      console.error("Missing Mixpanel Token");
    }

    services.push(getCustomerIOSDKService());
  }

  return (
    <>
      <RemoteConfigProvider
        isDevEnv={process.env.REACT_APP_DEV_BUILD === "true"}
        product={"Kairos"}
        project={ProjectType.WEBAPP}
        app={app}
      >
        <AnalyticsProvider services={services} includeAppData={false}>
          <LanguageProvider>
            <AuthProvider
              reCaptchaKey="6LcEgdIZAAAAANRwraZQ9OJHMbXchth7wIFPDRmp"
              i18n={i18n}
              authInstance={auth}
            >
              <Elements stripe={stripePromise}>
                <StripeProvider>
                  <ReactNotification />
                  <AppRouter />
                </StripeProvider>
              </Elements>
            </AuthProvider>
          </LanguageProvider>
        </AnalyticsProvider>
      </RemoteConfigProvider>
    </>
  );
}

export default function App(): JSX.Element {
  return (
    <SuspenseLoader>
      <Component />
    </SuspenseLoader>
  );
}
