import { HookResult } from "hooks/HookResult";
import { useContext } from "react";
import RoutineContext, { RoutinesById } from "./RoutineContext";
import { NormalizrResult, parseAPIRoutineData } from "./Tools";
import { routine, task } from "models/Schema";
import { normalize } from "normalizr";
import { merge } from "lodash";
import TaskContext from "./TaskContext";
import FirebaseAPI from "@neurosolutionsgroup/api-client";
import { Routine } from "@neurosolutionsgroup/models";

export interface useRoutinesSelectors {
  routineIds: string[];
  routinesById: RoutinesById;
}

export interface useRoutineActions {
  updateRoutine: (updatedRoutine: Routine) => Promise<void>;
}

const useRoutines = (): HookResult<useRoutinesSelectors, useRoutineActions> => {
  const { routineIds, setRoutineIds, routinesById, setRoutinesById } =
    useContext(RoutineContext);

  const { setTasksById } = useContext(TaskContext);

  const updateRoutine = async (updatedRoutine: Routine) => {
    return FirebaseAPI.Routine.updateRoutine(updatedRoutine).then(
      (response) => {
        //  Renormalize the data to be stored in state.
        const rawData = {
          routines: parseAPIRoutineData([response]),
        };

        const data = normalize(rawData, {
          tasks: [task],
          routines: [routine],
        }) as NormalizrResult;

        //  Merge updated routine and tasks with state.
        setRoutineIds((prevState) => merge(prevState, data.result.routines));
        setRoutinesById((prevState) =>
          merge(prevState, data.entities.routines)
        );

        setTasksById((prevState) => merge(prevState, data.entities.tasks));
      }
    );
  };

  return {
    selectors: {
      routineIds,
      routinesById,
    },
    actions: {
      updateRoutine,
    },
  };
};

export default useRoutines;
