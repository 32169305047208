import { Loader } from "@neurosolutionsgroup/components";
import React from "react";
import CompleteRegistrationFormComponent from "./CompleteRegistrationFormComponent";
import { useTranslation } from "react-i18next";

interface Props {
  isLoading: boolean;
  onSubmit: (firstName: string, lastName: string, city: string) => void;
}

const CompleteRegistrationPageComponent = ({
  isLoading,
  onSubmit,
}: Props): JSX.Element => {
  const { t } = useTranslation(["webappAuthentication"]);

  const blockName = "complete-registration";

  return (
    <div className={blockName}>
      {isLoading ? <Loader /> : null}
      <div className={blockName + "__body"}>
        <h2 className={blockName + "__title"}>
          {t("pages.completeRegistration.title")}
        </h2>
        <CompleteRegistrationFormComponent
          isSubmitEnabled={!isLoading}
          onSubmit={(firstName, lastName, city) =>
            onSubmit(firstName, lastName, city)
          }
        />
      </div>
    </div>
  );
};

export default CompleteRegistrationPageComponent;
