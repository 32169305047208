import React, { Suspense } from "react";

import { Redirect } from "react-router-dom";

function Component() {
  return <Redirect to="/download" />;
}

export default function Links(): JSX.Element {
  return (
    <Suspense fallback="loading">
      <Component />
    </Suspense>
  );
}
