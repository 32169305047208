import { Coupon, LocalizationInfo, Price } from "@neurosolutionsgroup/models";
import React, { ReactNode, SetStateAction, useState } from "react";

export interface StripeContextData {
  prices: Price[];
  setPrices: (prices: Price[]) => void;
  referralCoupon: Coupon | null;
  setReferralCoupon: React.Dispatch<SetStateAction<Coupon | null>>;
  localization?: LocalizationInfo;
  setLocalization: React.Dispatch<SetStateAction<LocalizationInfo | undefined>>;
}

export const StripeContextDefaultData: StripeContextData = {
  prices: [],
  setPrices: () => {},
  referralCoupon: null,
  setReferralCoupon: () => {},
  localization: undefined,
  setLocalization: () => {},
};

const StripeContext = React.createContext<StripeContextData>(
  StripeContextDefaultData
);

export type Props = {
  children: ReactNode;
};

export const StripeProvider = (props: Props): JSX.Element => {
  const [prices, setPrices] = useState<Price[]>([]);
  const [referralCoupon, setReferralCoupon] = useState<Coupon | null>(null);
  const [localization, setLocalization] = useState<LocalizationInfo>();

  return (
    <StripeContext.Provider
      value={{
        prices,
        setPrices,
        referralCoupon,
        setReferralCoupon,
        localization,
        setLocalization,
      }}
    >
      {props.children}
    </StripeContext.Provider>
  );
};

export default StripeContext;
