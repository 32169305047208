import React from "react";

export interface FormGroupProps {
  name: string;
  label?: string;
  showValidation?: boolean;
  validationMessage?: string;
  children: React.ReactNode;
}

/**
 * Wrapper for a form input containing the label and validation message.
 */
const FormGroup: React.FunctionComponent<FormGroupProps> = ({
  name,
  label,
  showValidation = false,
  validationMessage,
  ...props
}: FormGroupProps) => {
  return (
    <div className="form__form-group">
      {label ? <label htmlFor={name}>{label}</label> : null}
      {props.children}
      {showValidation ? (
        <div className="form__validation-message">{validationMessage}</div>
      ) : null}
    </div>
  );
};

export default FormGroup;
