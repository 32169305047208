import React, { useContext, useEffect } from "react";
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
import Dashboard from "./dashboard/Dashboard";
import Page from "../../components/layout/pages/Page";
import Settings from "./settings/Settings";
import AppHeader from "components/layout/header/AppHeader";
import Billing from "./billing/Billing";
import PromoCode from "./billing/promo/PromoCode";
import queryString from "query-string";
import AppDataContext, { AppDataProvider } from "hooks/data/AppDataContext";
import { RoutineProvider } from "hooks/data/RoutineContext";
import { ChildProvider } from "hooks/data/ChildContext";
import { TaskProvider } from "hooks/data/TaskContext";
import { UserProfileProvider } from "hooks/account/UserProfileContext";
import { Loader } from "@neurosolutionsgroup/components";
import SubscriptionBanner from "./dashboard/SubscriptionBanner";
import SimpleHeader from "components/layout/header/SimpleHeader";

const ParentAppComponent = () => {
  const urlParams = useLocation().search;
  const params = queryString.parse(urlParams);
  const history = useHistory();

  const { dataFetched: appDataFetched } = useContext(AppDataContext);

  useEffect(() => {
    if (params.autoCheckout === "true") {
      const queryParams = new URLSearchParams(urlParams);

      history.push({
        pathname: "/app/billing",
        search: queryParams.toString(),
      });
    } else if (params.redirect) {
      const redirect = params.redirect.toString();

      const queryParams = new URLSearchParams(urlParams);

      if (queryParams.has("redirect")) {
        queryParams.delete("redirect");
      }

      params.redirect = null;

      history.push({
        pathname: redirect,
        search: queryParams.toString(),
      });
    }
  }, []);

  return (
    <div className="background-body background-beige w-100 h-100">
      <Switch>
        <Route
          exact
          path="/app/billing"
          render={() => <SimpleHeader onBack={() => history.goBack()} />}
        />
        <Route path="/app" component={AppHeader} />
      </Switch>
      <Route path="/app/dashboard" component={SubscriptionBanner} />
      {appDataFetched ? (
        <Page>
          <Switch>
            <Route exact path="/app/billing" component={Billing} />
            <Route exact path="/app/promocode" component={PromoCode} />
            <Route path="/app/settings*" component={Settings} />
            <Route path="/app/dashboard" component={Dashboard} />

            <Redirect to="/app/dashboard" />
          </Switch>
        </Page>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default function ParentApp(): JSX.Element {
  return (
    <RoutineProvider>
      <ChildProvider>
        <TaskProvider>
          <AppDataProvider>
            <UserProfileProvider>
              <ParentAppComponent />
            </UserProfileProvider>
          </AppDataProvider>
        </TaskProvider>
      </ChildProvider>
    </RoutineProvider>
  );
}
