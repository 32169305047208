import KAIROS_1_logo_illustratif_EN_small from "./KAIROS-1-logo-illustratif-EN-small.png";
import KAIROS_1_logo_illustratif_FR_small from "./KAIROS-1-logo-illustratif-FR-small.png";
import KAIROS_2_logo_simple_tagEN_rouge_RGB_small from "./KAIROS-2-logo-simple-tagEN-rouge-RGB-small.png";
import KAIROS_2_logo_simple_tagFR_rouge_RGB_small from "./KAIROS-2-logo-simple-tagFR-rouge-RGB-small.png";
import KAIROS_5_logo_simple_seul_betaweb from "./KAIROS-5-logo-simple-seul-betaweb.png";
import KAIROS_5_logo_simple_seul_blanc_small from "./KAIROS-5-logo-simple-seul-blanc-small.png";
import KAIROS_5_logo_simple_tagEN_blanc_small from "./KAIROS-5-logo-simple-tagEN-blanc-small.png";
import KAIROS_5_logo_simple_tagFR_blanc_small from "./KAIROS-5-logo-simple-tagFR-blanc-small.png";

const Logos = {
  KAIROS_1_logo_illustratif_EN_small,
  KAIROS_1_logo_illustratif_FR_small,
  KAIROS_2_logo_simple_tagEN_rouge_RGB_small,
  KAIROS_2_logo_simple_tagFR_rouge_RGB_small,
  KAIROS_5_logo_simple_seul_betaweb,
  KAIROS_5_logo_simple_seul_blanc_small,
  KAIROS_5_logo_simple_tagEN_blanc_small,
  KAIROS_5_logo_simple_tagFR_blanc_small,
};

export default Logos;
