import React from "react";

//  Components.
import { SuspenseLoader } from "components/graphics";
import ProfileSettings from "./profile/ProfileSettings";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Component = () => {
  const { t } = useTranslation(["webappSettings"]);

  return (
    <>
      <Row className="my-3">
        <Col>
          <h1>{t("title")}</h1>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <ProfileSettings />
        </Col>
      </Row>
    </>
  );
};

export default function Settings(): JSX.Element {
  return (
    <SuspenseLoader>
      <Component />
    </SuspenseLoader>
  );
}
