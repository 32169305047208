import React from "react";

import { useTranslation } from "react-i18next";

import { LogoCard } from "components/layout/cards";
import { CardPage } from "components/layout/pages";
import { SuspenseLoader } from "components/graphics/SuspenseLoader";

//  Interfaqce displayed if error encountered in email processes.
function Component() {
  const { t } = useTranslation(["webappAuthentication"]);

  return (
    <CardPage>
      <LogoCard className="bg-beige">
        <div>
          <h2>{t("callbacks.error.title")}</h2>
          <hr />
          <p className="text-center">{t("callbacks.error.subtitle")}</p>
        </div>
      </LogoCard>
    </CardPage>
  );
}

export default function Error(): JSX.Element {
  return (
    <SuspenseLoader>
      <Component />
    </SuspenseLoader>
  );
}
