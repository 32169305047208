import type { Locale as DateFnsLocale } from "date-fns";
import React, { PropsWithChildren, useContext, useMemo, useState } from "react";
import i18next from "i18next";

// there's no neutral 'en' because date formats change between English locales
// (e.g. US: "March 29, 2022"; GB: "29 March 2022"); let's use 'CA' for now.
import enDateLocale from "date-fns/locale/en-CA";
import frDateLocale from "date-fns/locale/fr";
import deDateLocale from "date-fns/locale/de";
import { Language } from "@neurosolutionsgroup/models";
import { useRemoteConfig } from "@neurosolutionsgroup/remote-config";

const dateLocales: Record<Language, DateFnsLocale> = {
  en: enDateLocale,
  fr: frDateLocale,
  de: deDateLocale,
};

interface LanguageHookResult {
  language: Language;
  dateLocale: DateFnsLocale;
  setLanguage: (lang: Language) => void;
  languages: Language[];
}

export const LanguageContext = React.createContext<LanguageHookResult>({
  language: "en",
  dateLocale: dateLocales["en"],
  setLanguage: () => {
    return;
  },
  languages: ["en"],
});

export const LanguageProvider: React.FC<PropsWithChildren> = (props) => {
  const { getActivatedLanguages } = useRemoteConfig();

  const [language, setLanguage] = useState<Language>(
    getActivatedLanguages()[0]
  );

  const languages = useMemo(() => {
    return getActivatedLanguages();
  }, [getActivatedLanguages]);

  return (
    <LanguageContext.Provider
      value={{
        language,
        dateLocale: dateLocales[language],
        setLanguage,
        languages,
      }}
    >
      {props.children}
    </LanguageContext.Provider>
  );
};

const useLanguage = (): LanguageHookResult => {
  const { language, dateLocale, setLanguage, languages } =
    useContext(LanguageContext);

  const setLanguageProxy = (language: Language): void => {
    if (languages.includes(language)) {
      setLanguage(language);
      i18next.changeLanguage(language);
    }
  };

  return {
    language,
    dateLocale,
    setLanguage: setLanguageProxy,
    languages,
  };
};

export default useLanguage;
