import { Currency, LocalizationInfo } from "@neurosolutionsgroup/models";

//  Detect location and corresponding currency using api call.
export const getLocalCurrency = async (): Promise<LocalizationInfo> => {
  return fetch("https://extreme-ip-lookup.com/json/?key=BE5c6GPuzkyOSjvQXYbR")
    .then((res) => res.json())
    .then((response) => {
      let currency: Currency;

      if (response.continent === "Europe") {
        currency = Currency.EUR;
      } else if (response.country === "Canada") {
        currency = Currency.CAD;
      } else {
        currency = Currency.USD;
      }

      return {
        currency: currency,
        country: response.country,
        countryCode: response.countryCode,
        continent: response.continent,
        region: response.region,
        ipAddress: response.query,
      };
    })
    .catch((err) => {
      return {
        currency: Currency.USD,
        country: "",
        countryCode: "",
        continent: "",
        region: "",
        ipAddress: "",
      };
    });
};
