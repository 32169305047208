import { Loader } from "@neurosolutionsgroup/components";
import React from "react";
import ResetPasswordFormComponent from "./ResetPasswordFormComponent";
import { useTranslation } from "react-i18next";

interface Props {
  onSubmit: (password: string) => void;
  isLoading: boolean;
}

const ResetPasswordPageComponent = ({
  onSubmit,
  isLoading,
}: Props): JSX.Element => {
  const { t } = useTranslation(["webappAuthentication"]);

  const blockName = "reset-password";

  return (
    <div className={blockName}>
      {isLoading ? <Loader /> : null}
      <div className={blockName + "__body"}>
        <h1 className={blockName + "__title"}>
          {t("pages.resetPassword.title")}
        </h1>
        <hr />
        <p>{t("pages.resetPassword.subtitle")}</p>
        <hr />
        <ResetPasswordFormComponent onSubmit={onSubmit} isLoading={isLoading} />
      </div>
    </div>
  );
};

export default ResetPasswordPageComponent;
