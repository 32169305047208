import React from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { Navbar, Nav, Container } from "react-bootstrap";
import { SuspenseLoader } from "components/graphics/SuspenseLoader";
import LanguagePicker from "./LanguagePicker";
import Logos from "assets/Logos";

const Component = () => {
  //  Language management.
  const { t } = useTranslation(["webappBase", "images"]);

  return (
    <Navbar bg="none" className="custom-navbar" expand="lg">
      <Container>
        <Navbar.Brand>
          <a href="https://kairosgame.com">
            <img
              className="header-logo"
              alt="Kairos"
              src={Logos.KAIROS_5_logo_simple_seul_blanc_small}
            />
          </a>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="navbar-nav" className="btn btn-primary" />

        <Navbar.Collapse id="navbar-nav">
          <Nav className="ml-auto float-right">
            <div className="ml-2 mb-2">
              <div className="button-wrap">
                <Link to="/account/login">
                  <button className="btn btn-primary-alt px-4">
                    {t("nav.login")}
                  </button>
                </Link>
              </div>
            </div>
            <LanguagePicker />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default function Header(): JSX.Element {
  return (
    <SuspenseLoader>
      <Component />
    </SuspenseLoader>
  );
}
