import React from "react";
import { DetailedHTMLProps } from "react";

const KairosBackButton: React.FC<
  DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
> = ({ ...props }) => {
  return (
    <div className={props.className + " back-button__wrapper"}>
      <button {...props} className="back-button__element">
        <i className="icon-backarrow"></i>
      </button>
    </div>
  );
};

export default KairosBackButton;
