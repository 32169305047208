import React from "react";
import { useState } from "react";
import AuthError from "../../commons/AuthError/AuthError";
import useRecovery from "../../hooks/useRecovery/useRecovery";
import ResetPasswordPageComponent from "./ResetPasswordPageComponent";

interface Props {
  onSuccess: () => void;
  onFailure: (err: AuthError) => void;
  /** Token received in the url of a password reset link */
  token: string;
}

const ResetPasswordPage = ({
  onSuccess,
  onFailure,
  token,
}: Props): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    actions: { resetPassword },
  } = useRecovery();

  const onSubmit = (password: string) => {
    setIsLoading(true);

    const data = {
      password,
      token,
    };

    resetPassword(data)
      .then(onSuccess)
      .catch(onFailure)
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <ResetPasswordPageComponent onSubmit={onSubmit} isLoading={isLoading} />
  );
};

export default ResetPasswordPage;
