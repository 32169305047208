import { HookResult } from "hooks/HookResult";
import { ChildrenById, TasksToValidate, useChildContext } from "./ChildContext";

export interface useChildrenSelectors {
  childIds: string[];
  childrenById: ChildrenById;
  selectedChildId: string | undefined;
  tasksToValidate: TasksToValidate;
}

export interface useChildrenActions {
  setSelectedChildId: (userId: string) => void;
}

const useChildren = (): HookResult<
  useChildrenSelectors,
  useChildrenActions
> => {
  const {
    childIds,
    childrenById,
    selectedChildId,
    tasksToValidate,
    setSelectedChildId,
  } = useChildContext();

  return {
    selectors: {
      childIds,
      childrenById,
      selectedChildId,
      tasksToValidate,
    },
    actions: {
      setSelectedChildId,
    },
  };
};

export default useChildren;
