import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";
import firebase from "services/firebase";

//  Internationalisation.
import initI18n from "./i18n";

initI18n();

firebase.initializeFirebaseApp();

const container = document.getElementById("root");

if (!container) {
  throw new Error("Container element not found in DOM.");
}

const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
