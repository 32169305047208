import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import AuthError from "../../commons/AuthError/AuthError";
import { AuthHookResult } from "../../commons/AuthHookResult/AuthHookResult";
import { AccountRecoveryArgs, ResetPasswordArgs } from "../../types";
import FirebaseAPI from "@neurosolutionsgroup/api-client";

interface UseRecoveryActions {
  recoverAccount: (
    args: AccountRecoveryArgs,
    language: string
  ) => Promise<boolean>;
  resetPassword: (args: ResetPasswordArgs) => Promise<boolean>;
  requestPasswordReset: () => Promise<boolean>;
}

const useRecovery = (): AuthHookResult<
  Record<string, never>,
  UseRecoveryActions
> => {
  /**
   * Request a password recovery message from the back-end.
   * @param args @type {AccountRecoveryArgs}
   * @returns {boolean} Success status of the request.
   */
  const recoverAccount = async (
    args: AccountRecoveryArgs,
    language: string
  ): Promise<boolean> => {
    const auth = getAuth();

    auth.languageCode = language;

    return sendPasswordResetEmail(auth, args.email)
      .then(() => {
        return true;
      })
      .catch((error) => {
        if (error.code === "auth/user-not-found") {
          return FirebaseAPI.Account.requestPasswordReset(
            args.email,
            args.captchaResponse
          )
            .then(() => {
              return true;
            })
            .catch((err) => {
              return Promise.reject(AuthError.handleError(err));
            });
        } else {
          return Promise.reject(error);
        }
      });
  };

  const requestPasswordReset = async (): Promise<boolean> => {
    const auth = getAuth();

    if (auth.currentUser?.email) {
      return sendPasswordResetEmail(auth, auth.currentUser.email).then(
        () => true
      );
    } else {
      return false;
    }
  };

  /**
   * Reset the account password using a token received in a recovery message.
   * @param args @type {ResetPasswordArgs} New password and token received by email.
   * @returns {boolean} Success status of the request.
   */
  const resetPassword = async (args: ResetPasswordArgs): Promise<boolean> => {
    return FirebaseAPI.Account.resetPassword(args.password, args.token)
      .then(() => {
        return true;
      })
      .catch((err) => {
        return Promise.reject(AuthError.handleError(err));
      });
  };

  return {
    selectors: {},
    actions: {
      recoverAccount,
      resetPassword,
      requestPasswordReset,
    },
  };
};

export default useRecovery;
