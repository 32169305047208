import Avatar_checkWatch_nobg from "./AvatarGirl_checkWatch_nobg.png";
import GroupCelebration from "./avatar_group_celebration.png";
import GroupCelebrationL from "./avatar_group_celebration_l.png";
import GroupCelebrationR from "./avatar_group_celebration_r.png";
import GroupCelebrationLG from "./avatar_group_celebration_lg.png";
import RunningSM from "./avatar_running_sm.png";
import ThumbsUp from "./avatar_thumbs_up.png";

const AvatarAssets = {
  Avatar_checkWatch_nobg,
  GroupCelebration,
  GroupCelebrationL,
  GroupCelebrationR,
  GroupCelebrationLG,
  RunningSM,
  ThumbsUp,
};

export default AvatarAssets;
