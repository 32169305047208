import Challenges from "./Challenges";
import Medical from "./Medical";
import Skills from "./Skills";

const Data = {
  Challenges,
  Medical,
  Skills,
};

export default Data;
