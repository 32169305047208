import { Icons } from "@neurosolutionsgroup/components";
import { BevelButton } from "components/inputs";
import KairosBackButton from "components/inputs/BackButton/KairosBackButton";
import React from "react";
import { Container, Navbar, NavItem } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import LanguagePicker from "./LanguagePicker";

interface SimpleHeaderProps {
  onBack?: VoidFunction;
  backText?: string;
  goToWebApp?: boolean;
}

const SimpleHeader: React.FC<SimpleHeaderProps> = ({
  onBack,
  backText,
  goToWebApp = false,
}) => {
  //  Language management.
  const { t } = useTranslation(["webappBase"]);

  const history = useHistory();

  return (
    <Navbar bg="none" className="custom-navbar app" expand="md">
      <Container className="py-2">
        <NavItem>
          {onBack ? <KairosBackButton onClick={onBack} /> : null}
        </NavItem>
        <Navbar.Collapse>
          {onBack ? (
            <NavItem className="mr-auto  ml-2 font-weight-bold">
              {backText ?? t("nav.return")}
            </NavItem>
          ) : null}
          {goToWebApp ? (
            <NavItem>
              <BevelButton
                onClick={() => history.push("/app/dashboard")}
                className="btn header__button"
              >
                <div className="d-flex align-items-center justify-content-center">
                  {t("nav.goToWebApp")}
                  <Icons.ArrowIcon
                    className="header__button-arrow"
                    arrowDirection="right"
                    color="#ef4044"
                  />
                </div>
              </BevelButton>
            </NavItem>
          ) : null}
        </Navbar.Collapse>
        <NavItem>
          <LanguagePicker />
        </NavItem>
      </Container>
    </Navbar>
  );
};

export default SimpleHeader;
