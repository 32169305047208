import { FrontEndRoutine } from "models/Types";
import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useState,
} from "react";

export type RoutinesById = {
  [key: string]: FrontEndRoutine;
};

export interface RoutineContextData {
  routineIds: string[];
  setRoutineIds: Dispatch<SetStateAction<string[]>>;
  routinesById: RoutinesById;
  setRoutinesById: Dispatch<SetStateAction<RoutinesById>>;
}

const RoutineContext = React.createContext<RoutineContextData>({
  routineIds: [],
  setRoutineIds: () => {},
  routinesById: {},
  setRoutinesById: () => {},
});

export const RoutineProvider = (props: PropsWithChildren): JSX.Element => {
  const [routineIds, setRoutineIds] = useState<string[]>([]);
  const [routinesById, setRoutinesById] = useState<RoutinesById>({});

  return (
    <RoutineContext.Provider
      value={{ routineIds, setRoutineIds, routinesById, setRoutinesById }}
    >
      {props.children}
    </RoutineContext.Provider>
  );
};

export default RoutineContext;
