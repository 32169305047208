export enum AuthErrorCode {
  Unknown = "5000",
  ContextNotDefined = "5001",
  Unauthenticated = "4000",
  FailedRecaptcha = "4001",
  AccountNotFound = "1001",
  EmailInvalid = "1005",
  EmailTaken = "1006",
  PasswordInvalid = "1008",
}

class AuthError {
  public code: AuthErrorCode;
  public message?: string;

  constructor(code: AuthErrorCode, message: string | undefined = undefined) {
    this.code = code;
    this.message = message;
  }

  // Ignore these next two warning in this case as handleError is designed to handle any error type.
  // @typescript-eslint/explicit-module-boundary-types @typescript-eslint/no-explicit-any
  // eslint-disable-next-line
  static handleError = (error: any): AuthError => {
    //  Default error value.
    let errorObj = "unknown";

    if (error) {
      if (error.response) {
        errorObj = error.response.data.toString();
      } else if (error.request) {
        errorObj = error.request;
      } else if (error.statusCode && error.message) {
        errorObj = error.message;
      } else {
        errorObj = error;
      }

      if (
        Object.values(AuthErrorCode).includes(
          errorObj.toString() as AuthErrorCode
        )
      ) {
        return new AuthError(errorObj.toString() as AuthErrorCode);
      }
    }

    return new AuthError(AuthErrorCode.Unknown);
  };
}

export default AuthError;
