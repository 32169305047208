import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import CardPage from "components/layout/pages/CardPage";
import LogoCard from "components/layout/cards/LogoCard";
import { SuspenseLoader } from "components/graphics";
import { dangerNotification } from "components/notifications";
import { store } from "react-notifications-component";
import { useTranslation } from "react-i18next";
import { CompleteRegistrationPage, AuthError } from "services/authentication";
import {
  WebAppRegistrationCompleted,
  useAnalytics,
} from "@neurosolutionsgroup/analytics";

const Component = () => {
  const history = useHistory();
  const { t } = useTranslation(["webappBase"]);
  const location = useLocation();
  const urlParams = location.search;

  const { handleEvent } = useAnalytics();

  const onSuccess = (firstName: string, lastName: string) => {
    const fullName = `${firstName} ${lastName}`;

    const event: WebAppRegistrationCompleted = {
      name: "Web App Registration Completed",
      setProperties: {
        name: fullName,
      },
      setOnceProperties: {
        "Account Completed On": new Date().toISOString(),
      },
    };

    handleEvent(event);

    history.push({ pathname: "/app/dashboard", search: urlParams });
  };
  const onFailure = (err: AuthError) => {
    store.addNotification(
      dangerNotification(
        t("forms.errors.title"),
        t("forms.errors.general") + ": " + t("forms.errors." + err.code),
        false
      )
    );
  };

  return (
    <CardPage>
      <LogoCard className="bg-beige">
        <CompleteRegistrationPage onSuccess={onSuccess} onFailure={onFailure} />
      </LogoCard>
    </CardPage>
  );
};

export default function CompleteRegistration(): JSX.Element {
  return (
    <SuspenseLoader>
      <Component />
    </SuspenseLoader>
  );
}
