import { LogoCard, PlainCard } from "components/layout/cards";
import { CardPage } from "components/layout/pages";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BevelButton } from "components/inputs";
import SimpleHeader from "components/layout/header/SimpleHeader";
import QRCode from "react-qr-code";
import { useHistory } from "react-router-dom";
import { Tools } from "@neurosolutionsgroup/tools";
import appStoreAssets from "assets/appstore";
import useLanguage from "hooks/useLanguage";
import {
  AppStore,
  DownloadLinkOpened,
  useAnalytics,
} from "@neurosolutionsgroup/analytics";

const Download: React.FC = () => {
  const { t } = useTranslation(["webappAuthentication", "webappBase"]);
  const { language } = useLanguage();
  const history = useHistory();
  const { handleEvent } = useAnalytics();

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      let check = false;

      (function (a) {
        if (
          // Ignored as escape characters seem to be necessary for RegExp.
          // eslint-disable-next-line no-useless-escape
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
            a
          ) ||
          // Ignored as escape characters seem to be necessary for RegExp.
          // eslint-disable-next-line no-useless-escape
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substring(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor);

      setIsMobile(check);
    };

    checkMobile();
  }, []);

  const clickTrack = (store: AppStore) => {
    const event: DownloadLinkOpened = {
      name: "Download Link Opened",
      eventProperties: {
        store,
      },
    };

    handleEvent(event);
  };

  return (
    <div className="background-body background">
      <SimpleHeader onBack={() => history.goBack()} goToWebApp={true} />
      <CardPage>
        <LogoCard className="bg-beige">
          <div>
            <h2>{t("pages.links.title")}</h2>
            <hr />
            <p className="text-center">{t("pages.links.subtitle")}</p>
            {isMobile ? null : (
              <p className="text-center">{t("pages.links.text")}</p>
            )}
            <hr className="mb-0" />
          </div>
          {isMobile ? (
            <div>
              <div>
                <div>
                  <a
                    href="https://kairos.app.link/hvKZt0EXJ7"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => clickTrack("Google")}
                  >
                    <img
                      alt={t("webappBase:components.googleplayalt")}
                      className="w-100"
                      src={
                        "https://play.google.com/intl/en_us/badges/static/images/badges/" +
                        t("webappBase:components.googleplaybadge") +
                        "_badge_web_generic.png"
                      }
                    />
                  </a>
                </div>
              </div>
              <div className="w-100">
                <div>
                  <a
                    href="https://kairos.app.link/hvKZt0EXJ7"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => clickTrack("Apple")}
                  >
                    <img
                      alt={t("webappBase:components.appstorealt")}
                      className="w-100 px-4"
                      src={Tools.Language.languageSwitch(language, {
                        en: appStoreAssets.appStoreEN,
                        fr: appStoreAssets.appStoreFRCA,
                      })}
                    />
                  </a>
                </div>
              </div>
            </div>
          ) : (
            <div className="my-3">
              <h5>{t("pages.download.qr.title")}</h5>
              <div className="d-flex justify-content-center">
                <div className="p-2 bg-white">
                  <QRCode
                    value="https://kairos.app.link/hvKZt0EXJ7"
                    size={128}
                  />
                </div>
              </div>
            </div>
          )}
        </LogoCard>
        <div className="pt-3 pb-2 col-xl-5 col-lg-6 col-md-7 col-sm-10">
          <div className="download__divider">
            <h4>or</h4>
          </div>
        </div>
        <PlainCard
          className="bg-beige p-3"
          outerClasses="col-xl-5 col-lg-6 col-md-7 col-sm-10"
        >
          <h5 className="py-2">{t("pages.download.subscribe.title")}</h5>
          <BevelButton
            onClick={() => history.push("/app/billing")}
            type="button"
            className="btn btn-primary w-100"
          >
            {t("pages.download.subscribe.action")}
          </BevelButton>
        </PlainCard>
      </CardPage>
    </div>
  );
};

export default Download;
