import React from "react";
import { useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useTranslation } from "react-i18next";
import AuthError, { AuthErrorCode } from "../../commons/AuthError/AuthError";
import useRecovery from "../../hooks/useRecovery/useRecovery";
import RecoverAccountPageComponent from "./RecoverAccountPageComponent";

interface Props {
  onSuccess: () => void;
  onFailure: (err: AuthError) => void;
}

const RecoverAccountPage = ({ onSuccess, onFailure }: Props): JSX.Element => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const { i18n } = useTranslation();

  const {
    actions: { recoverAccount },
  } = useRecovery();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = async (email: string) => {
    setIsLoading(true);

    if (!executeRecaptcha) {
      return;
    }

    const captchaResponse = await executeRecaptcha();

    const data = {
      email,
      captchaResponse,
    };

    if (captchaResponse != null) {
      recoverAccount(data, i18n.language)
        .then(() => {
          onSuccess();
        })
        .catch((error) => {
          onFailure(error as AuthError);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      onFailure(new AuthError(AuthErrorCode.FailedRecaptcha));

      setIsLoading(false);
    }
  };

  return (
    <RecoverAccountPageComponent onSubmit={onSubmit} isLoading={isLoading} />
  );
};

export default RecoverAccountPage;
