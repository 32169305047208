import GoogleSmall from "./Google_small.png";
import GreenCheckmarkTag from "./green_checkmark_tag.png";
import GreenCheckmark from "./green_checkmark.png";
import IconClipboard from "./icon_clipboard.png";

const IconAssets = {
  GoogleSmall,
  GreenCheckmarkTag,
  GreenCheckmark,
  IconClipboard,
};

export default IconAssets;
