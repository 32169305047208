import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { PlainCard } from "components/layout/cards";
import { BevelBorderButton } from "components/inputs";
import useTasks from "hooks/data/useTasks";
import { ValidationTaskHistory } from "hooks/data/ChildContext";
import TaskAssets from "assets/TaskIcons";

interface TaskProps {
  task: ValidationTaskHistory;
  index: number;
  setTaskStatus: (index: number, status: boolean | null) => void;
}

const Task: FunctionComponent<TaskProps> = ({
  task,
  index,
  setTaskStatus,
  ...props
}) => {
  const { t } = useTranslation(["webappDashboard"]);

  const handleTaskSwitch = (status: boolean | null) => {
    setTaskStatus(index, status);
  };

  const {
    selectors: { tasksById },
  } = useTasks();

  return (
    <>
      <PlainCard className="p-3 mb-2 bg-beige">
        <div className="d-flex align-items-center">
          <div>
            <img
              alt={tasksById[task.task].icon.toString()}
              src={TaskAssets.TaskIcons[tasksById[task.task].icon]}
              className="task-icon"
            />
          </div>
          <div className="d-flex flex-column flex-grow-1 px-3">
            <p>
              <b>{tasksById[task.task].name}</b>
            </p>
          </div>
          <div className="flex-shrink-2 ml-auto">
            <div className="task-toggle-switch  d-flex align-items-center">
              <p
                className={
                  "d-none d-md-inline text-center task-status-message-" +
                  (task.status === null
                    ? ""
                    : task.status
                    ? "done"
                    : "not-done")
                }
              >
                <b>
                  {task.status === null
                    ? ""
                    : task.status
                    ? t("validation.done")
                    : t("validation.notdone")}
                </b>
              </p>
              <div className="d-flex ml-2">
                <BevelBorderButton
                  className={
                    "btn btn-primary btn-task-validation btn-task-validation-false " +
                    (!task.validated
                      ? ""
                      : task.status === false
                      ? "selected"
                      : "")
                  }
                  onClick={() => handleTaskSwitch(false)}
                >
                  <i className="icon-cross"></i>
                </BevelBorderButton>
                <BevelBorderButton
                  className={
                    "btn btn-primary btn-task-validation btn-task-validation-na ml-2 " +
                    (!task.validated
                      ? ""
                      : task.status === null
                      ? "selected"
                      : "")
                  }
                  onClick={() => handleTaskSwitch(null)}
                >
                  N/A
                </BevelBorderButton>
                <BevelBorderButton
                  className={
                    "btn btn-primary btn-task-validation btn-task-validation-true ml-2 " +
                    (task.status ? "selected" : "")
                  }
                  onClick={() => handleTaskSwitch(true)}
                >
                  <i className="icon-tick"></i>
                </BevelBorderButton>
              </div>
            </div>
          </div>
        </div>
      </PlainCard>
    </>
  );
};

export default Task;
