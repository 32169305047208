import React from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { Registration, Login, PasswordRecovery } from ".";
import CompleteRegistration from "./registration/CompleteRegistration";
import RecoverAccount from "./passwordrecovery/RecoverAccount";
import SimpleHeader from "components/layout/header/SimpleHeader";

const Account = (): JSX.Element => {
  const history = useHistory();

  return (
    <div className="background-body background">
      <SimpleHeader onBack={history.goBack} />
      <Switch>
        <Route exact path="/account">
          <Registration />
        </Route>
        <Route
          path="/account/completeregistration"
          component={CompleteRegistration}
        />
        <Route path="/account/login">
          <Login />
        </Route>
        <Route path="/account/forgottenpassword" component={RecoverAccount} />
        <Route path="/account/recoveraccount" component={RecoverAccount} />
        <Route path="/account/passwordrecovery" component={PasswordRecovery} />
        <Redirect to="/account" />
      </Switch>
    </div>
  );
};

export default Account;
