import { AuthHookResult } from "../../commons/AuthHookResult/AuthHookResult";
import { EmailLoginArgs } from "../../types";
import {
  getAuth,
  signInWithEmailAndPassword,
  UserCredential,
} from "firebase/auth";

interface UseLoginActions {
  loginByEmail: (args: EmailLoginArgs) => Promise<UserCredential>;
}

const useLogin = (): AuthHookResult<Record<string, never>, UseLoginActions> => {
  const loginByEmail = async (
    args: EmailLoginArgs
  ): Promise<UserCredential> => {
    const auth = getAuth();

    return signInWithEmailAndPassword(auth, args.email, args.password).catch(
      (err) => {
        console.log(err);
        return Promise.reject(err);
      }
    );
  };

  return {
    selectors: {},
    actions: {
      loginByEmail,
    },
  };
};

export default useLogin;
