import { FirebaseApp, initializeApp } from "firebase/app";
import {
  connectAuthEmulator,
  getAuth,
  inMemoryPersistence,
  setPersistence,
} from "firebase/auth";
import { initializeFirestore } from "firebase/firestore";
import { getPerformance } from "firebase/performance";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_CONFIG_API_KEY,
  authDomain: process.env.REACT_APP_WEBAPP_FIREBASE_CONFIG_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_CONFIG_PROJET_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_CONFIG_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_CONFIG_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_WEBAPP_FIREBASE_CONFIG_APP_ID,
};

const initializeFirebaseApp = (): FirebaseApp => {
  const fbApp = initializeApp(firebaseConfig);

  const auth = getAuth(fbApp);

  if (process.env.NODE_ENV === "development") {
    connectAuthEmulator(auth, "http://127.0.0.1:9099");

    initializeFirestore(fbApp, {
      experimentalForceLongPolling: true,
      host: "127.0.0.1:8080",
      ssl: false,
    });
  }

  if (process.env.NODE_ENV !== "development") {
    getPerformance(fbApp);

    setPersistence(auth, inMemoryPersistence);
  }

  return fbApp;
};

const firebase = {
  initializeFirebaseApp,
};

export default firebase;
