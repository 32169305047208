import React, { PropsWithChildren, useState } from "react";
import { useField } from "formik";

interface PasswordFieldProps {
  hideLabel: boolean;
  label?: string;
  id?: string;
  name: string;
}

const PasswordField = ({
  hideLabel,
  label,
  ...props
}: PropsWithChildren<PasswordFieldProps>): JSX.Element => {
  const [field, meta] = useField(props);
  const [show, setShow] = useState(false);

  return (
    <>
      {hideLabel ? null : (
        <label htmlFor={props.id || props.name}>{label}</label>
      )}
      <div className="input-group flex-nowrap password-field">
        <div className="input-wrap flex-grow-1 form-wrap-left-bevel">
          <input
            type={show ? "text" : "password"}
            {...field}
            {...props}
            className="form-control rounded-0 form-control-left-bevel"
          />
        </div>

        <div className="input-group-append">
          <button
            type="button"
            tabIndex={-1}
            className={
              "btn btn-primary btn-half-right py-0" +
              (show ? " password-show" : "")
            }
            onClick={() => setShow(!show)}
          >
            {show ? (
              <i className="icon-hide"></i>
            ) : (
              <i className="icon-show"></i>
            )}
          </button>
        </div>
      </div>
      {meta.touched && meta.error ? (
        <div className="text-danger">{meta.error}</div>
      ) : null}
    </>
  );
};

export default PasswordField;
