import { Field } from "formik";
import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";

interface RegistrationCheckboxesProps {
  setFieldValue: (field: string, value: boolean) => Promise<unknown>;
}

const RegistrationCheckboxes = ({
  setFieldValue,
}: RegistrationCheckboxesProps) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setFieldValue("termsAccepted", false);
    setFieldValue("politicsAccepted", false);
  }, [i18n.language]);

  return (
    <>
      <div>
        <label>
          <Field type="checkbox" name="termsAccepted" />
          &nbsp;&nbsp;
          <Trans i18nKey={"webappAuthentication:terms.privacy.text"}>
            I accept the "product name"{" "}
            <a
              href={t("terms.privacy.link")}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy policy
            </a>
          </Trans>
        </label>
      </div>
      <div>
        <label>
          <Field type="checkbox" name="politicsAccepted" />
          &nbsp;&nbsp;
          <Trans i18nKey={"webappAuthentication:terms.use.text"}>
            I accept the "product name"{" "}
            <a
              href={t("terms.use.link")}
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms and Conditions
            </a>
          </Trans>
        </label>
      </div>
    </>
  );
};

export default RegistrationCheckboxes;
