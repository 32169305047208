import {
  CompleteAccountInfo,
  SubscriptionStatus,
} from "@neurosolutionsgroup/models";
import React, {
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import FirebaseAPI from "@neurosolutionsgroup/api-client";

export interface UserProfileContextData {
  userProfile: CompleteAccountInfo | undefined;
  setUserProfile: React.Dispatch<
    SetStateAction<CompleteAccountInfo | undefined>
  >;
  subscriptionStatus: SubscriptionStatus | null;
  setSubscriptionStatus: React.Dispatch<
    SetStateAction<SubscriptionStatus | null>
  >;
  dataFetched: boolean;
}

const UserProfileContext = React.createContext<UserProfileContextData>({
  userProfile: undefined,
  setUserProfile: () => {},
  subscriptionStatus: null,
  setSubscriptionStatus: () => {},
  dataFetched: false,
});

export const UserProfileProvider = (props: PropsWithChildren): JSX.Element => {
  const [userProfile, setUserProfile] = useState<CompleteAccountInfo>();
  const [subscriptionStatus, setSubscriptionStatus] =
    useState<SubscriptionStatus | null>(null);
  const [dataFetched, setDataFetched] = useState<boolean>(false);

  useEffect(() => {
    const getProfileData = async () => {
      const [userInfo, subscriptionStatus] = await Promise.all([
        FirebaseAPI.Account.getUserInfo(),
        FirebaseAPI.Billing.Subscription.getSubscriptionStatus(),
      ]);

      setUserProfile(userInfo);
      setSubscriptionStatus(subscriptionStatus);
      setDataFetched(true);
    };

    getProfileData();
  }, []);

  return (
    <UserProfileContext.Provider
      value={{
        userProfile,
        setUserProfile,
        subscriptionStatus,
        setSubscriptionStatus,
        dataFetched,
      }}
    >
      {props.children}
    </UserProfileContext.Provider>
  );
};

export default UserProfileContext;
