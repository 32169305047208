import React from "react";
import { ButtonGroupProps } from "react-multi-carousel/lib/types";

interface CarouselButtonGroupProps extends ButtonGroupProps {
  className?: string;
  text: string;
}

const ButtonGroup = ({
  next,
  previous,
  ...props
}: CarouselButtonGroupProps): JSX.Element => {
  return (
    <div className="carousel-button-group">
      <button
        aria-label="Go to previous slide"
        onClick={() => (previous ? previous() : null)}
        className="btn btn-primary btn-half-left"
        disabled={props.carouselState?.currentSlide === 0}
      >
        <i className="icon-backarrow"></i>
      </button>
      <button
        aria-label="Go to next slide"
        onClick={() => (next ? next() : null)}
        className="btn btn-primary btn-half-right"
        disabled={
          props.carouselState
            ? props.carouselState.currentSlide ===
              props.carouselState.totalItems - props.carouselState.slidesToShow
            : true
        }
      >
        <i className="icon-arrow"></i>
      </button>
    </div>
  );
};

export default ButtonGroup;
