import i18n, { Resource } from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";
import Localization from "@neurosolutionsgroup/localization";
import { Tools } from "@neurosolutionsgroup/tools";

const resources: Resource = {
  en: {
    webappAuthentication: Localization.en.webappAuthentication,
    webappBase: Localization.en.webappBase,
    webappBilling: Localization.en.webappBilling,
    webappDashboard: Localization.en.webappDashboard,
    webappSettings: Localization.en.webappSettings,
    settings: Localization.en.settings,
    general: Localization.en.general,
  },
  fr: {
    webappAuthentication: Localization.fr.webappAuthentication,
    webappBase: Localization.fr.webappBase,
    webappBilling: Localization.fr.webappBilling,
    webappDashboard: Localization.fr.webappDashboard,
    webappSettings: Localization.fr.webappSettings,
    settings: Localization.fr.settings,
    general: Localization.fr.general,
  },
  de: {
    webappAuthentication: Localization.de.webappAuthentication,
    webappBase: Localization.de.webappBase,
    webappBilling: Localization.de.webappBilling,
    webappDashboard: Localization.de.webappDashboard,
    webappSettings: Localization.de.webappSettings,
    settings: Localization.de.settings,
    general: Localization.de.general,
  },
};

const initI18n = (): void => {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: "en",
      debug: !process.env.NODE_ENV || process.env.NODE_ENV === "development",
      interpolation: {
        escapeValue: false,
        defaultVariables: {
          product: Tools.Environment.getProductName(),
        },
      },
      supportedLngs: ["en", "fr", "de"],
      defaultNS: "webappBase",
      detection: {
        lookupQuerystring: "lang",
      },
      resources,
    });
};

export default initI18n;
