import { Auth, signInWithCustomToken, UserCredential } from "firebase/auth";
import { useCallback } from "react";
import { AuthHookResult } from "../../commons/AuthHookResult/AuthHookResult";

import { useAuthContext } from "../AuthContext/AuthContext";

export interface UseAuthSelectors {
  tenant: string | undefined;
  auth: Auth;
}

export interface UseAuthActions {
  isAuthenticated: () => boolean;
  getIdToken: () => Promise<string | undefined>;
  passAuthentication: (sessionTicket: string) => Promise<UserCredential>;
  invalidateSession: () => void;
}

const useAuth = (): AuthHookResult<UseAuthSelectors, UseAuthActions> => {
  const {
    user,
    tenant,
    invalidateSession: contextInvalidateSession,
    auth,
  } = useAuthContext();

  /**
   * A client side check of authentication. Does not guarantee authentication status with server-side PlayFab authentication.
   * @returns {boolean} Authentication status.
   */
  const isAuthenticated = useCallback((): boolean => {
    if (user && auth.currentUser) {
      return true;
    }

    return false;
  }, [user, auth]);

  /**
   * Receive a custom token generated by the /account/auth-token endpoint, and use it to authenticate user with Firebase.
   * @param customToken
   */
  const passAuthentication = (customToken: string): Promise<UserCredential> => {
    return signInWithCustomToken(auth, customToken);
  };

  const invalidateSession = async () => {
    await auth.signOut().then(() => {
      contextInvalidateSession();
    });
  };

  const getIdToken = async (): Promise<string | undefined> => {
    return auth.currentUser?.getIdToken();
  };

  return {
    selectors: {
      tenant,
      auth,
    },
    actions: {
      isAuthenticated,
      getIdToken,
      passAuthentication,
      invalidateSession,
    },
  };
};

export default useAuth;
