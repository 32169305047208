import { useState } from "react";
import LoginPageComponent from "./LoginPageComponent";
import AuthError from "../commons/AuthError/AuthError";
import useLogin from "../hooks/useLogin/useLogin";
import {
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
  UserCredential,
} from "firebase/auth";
import { useTranslation } from "react-i18next";
import { store } from "react-notifications-component";
import { dangerNotification } from "components/notifications";

interface Props {
  autoCheckout?: boolean;
  additionnalTitle?: string;
  onSuccess: (reponse: UserCredential, email: string) => void;
  onFailure: (err: AuthError) => void;
  passwordLink?: string;
  allowThirdParty?: boolean;
  email?: string;
}

const LoginPage = ({
  autoCheckout,
  additionnalTitle,
  onSuccess,
  onFailure,
  passwordLink,
  allowThirdParty,
  email,
}: Props): JSX.Element => {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    actions: { loginByEmail },
  } = useLogin();

  const executeCaptchaAsync = async (email: string, password: string) => {
    setIsLoading(true);

    const data = {
      email: email,
      password: password,
    };

    //  Check token returned and dispatch request to server.
    loginByEmail(data)
      .then((response) => {
        onSuccess(response, email);
      })
      .catch((err: AuthError) => {
        onFailure(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onLoginGoogle = async () => {
    setIsLoading(true);

    const provider = new GoogleAuthProvider();

    provider.addScope("https://www.googleapis.com/auth/userinfo.email");
    provider.addScope("https://www.googleapis.com/auth/userinfo.profile");

    const auth = getAuth();

    auth.languageCode = i18n.language;

    try {
      await signInWithPopup(auth, provider);
    } catch (err) {
      setIsLoading(false);

      store.addNotification(
        dangerNotification(null, t("webappBase:forms.errors.general"))
      );
    }
  };

  const onLoginApple = async () => {
    setIsLoading(true);

    const provider = new OAuthProvider("apple.com");

    provider.addScope("email");
    provider.addScope("name");

    const auth = getAuth();

    provider.setCustomParameters({
      locale: i18n.language,
    });

    try {
      await signInWithPopup(auth, provider);
    } catch (err) {
      setIsLoading(false);

      store.addNotification(
        dangerNotification(null, t("webappBase:forms.errors.general"))
      );
    }
  };

  return (
    <LoginPageComponent
      isLoading={isLoading}
      autoCheckout={autoCheckout}
      additionnalTitle={additionnalTitle}
      onSubmit={executeCaptchaAsync}
      onLoginGoogle={allowThirdParty ? onLoginGoogle : undefined}
      onLoginApple={allowThirdParty ? onLoginApple : undefined}
      passwordLink={passwordLink}
      email={email}
    />
  );
};

export default LoginPage;
