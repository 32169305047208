import React from "react";
import { Trans, useTranslation } from "react-i18next";

const Component = (): JSX.Element => {
  const { t } = useTranslation(["webappAuthentication"]);

  // prettier-ignore
  return (
    <p className="recaptcha-message">
      <Trans i18nKey="recaptcha.link">
      This site is protected by reCAPTCHA and the Google <a className="recaptcha-message__link" href={t("recaptcha.privacy")}> Privacy Policy</a> and <a className="recaptcha-message__link" href={t("recaptcha.terms")}>Terms of Service </a> apply.
      </Trans>
    </p>
  );
};

export default Component;
