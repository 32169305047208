import React, { useContext, useEffect, useState } from "react";

import { Row, Col } from "react-bootstrap";
import { PlainCard } from "components/layout/cards";
import { SuspenseLoader } from "components/graphics";

import Validation from "./validation/Validation";
import NoChildPlaceholder from "./NoChildPlaceholder";
import ChildPicker from "components/inputs/ChildPicker/ChildPicker";
import Today from "./graphics/Today/Today";
import AppDataContext from "hooks/data/AppDataContext";
import useChildren from "hooks/data/useChildren";
import { Loader } from "@neurosolutionsgroup/components";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import SubscriptionSuccessAlert from "components/alerts/SubscriptionSuccessAlert";
import { Trans, useTranslation } from "react-i18next";

const Component = () => {
  //  Hooks  //
  const location = useLocation();
  const urlParams = location.search;

  const { t } = useTranslation();

  const [showPurchaseComplete, setShowPurchaseComplete] = useState(false);

  useEffect(() => {
    const params = queryString.parse(urlParams);

    if (params.purchaseComplete !== undefined) {
      setShowPurchaseComplete(true);
    }
  }, []);

  const { dataFetched } = useContext(AppDataContext);

  const {
    selectors: { childIds, childrenById, selectedChildId, tasksToValidate },
    actions: { setSelectedChildId },
  } = useChildren();

  const changeUserIdHandler = (id: string) => {
    setSelectedChildId(id);
  };

  return (
    <>
      <div className="py-3">
        {!dataFetched ? (
          <>
            <Loader />
          </>
        ) : (
          <>
            <SubscriptionSuccessAlert
              open={showPurchaseComplete}
              onClose={() => setShowPurchaseComplete(false)}
            />
            {/* Test for no children. */}
            {!selectedChildId || (childIds && childIds.length === 0) ? (
              <Row>
                <Col>
                  <NoChildPlaceholder />
                </Col>
              </Row>
            ) : (
              <>
                <Row>
                  <Col lg={5}>
                    <div>
                      <Row className="grid-dashboard-top mb-2">
                        {childIds.length > 0 ? (
                          <Col>
                            <div className="">
                              <ChildPicker
                                userDefinitions={childrenById}
                                tasksToValidate={tasksToValidate}
                                userId={selectedChildId}
                                setUserId={changeUserIdHandler}
                              />
                            </div>
                          </Col>
                        ) : null}
                      </Row>
                      <Row>
                        <Col>
                          <PlainCard className="p-3 mb-3">
                            <Row>
                              <Col xs={1}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  height="24px"
                                  viewBox="0 -960 960 960"
                                  width="24px"
                                  fill="rgba(230, 156, 44, 1)"
                                >
                                  <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z" />
                                </svg>
                              </Col>
                              <Col xs={11}>
                                <p>
                                  <Trans i18nKey="webappDashboard:validation.warning.one" />
                                </p>
                                <p>
                                  {t("webappDashboard:validation.warning.two")}
                                </p>
                              </Col>
                            </Row>
                          </PlainCard>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <PlainCard className="p-3 dashboard-section-validation">
                            <Validation />
                          </PlainCard>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col lg={7}>
                    <div>
                      <Row>
                        <Col>
                          <Today />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default function Dashboard(): JSX.Element {
  return (
    <SuspenseLoader>
      <Component />
    </SuspenseLoader>
  );
}
