import { Tools } from "@neurosolutionsgroup/tools";
import Logos from "assets/Logos";
import useLanguage from "hooks/useLanguage";
import SuspenseLoader from "./SuspenseLoader";

function Component() {
  const { language } = useLanguage();

  return (
    <div className="logo">
      <img
        alt="Kairos Logo"
        src={Tools.Language.languageSwitch(language, {
          en: Logos.KAIROS_1_logo_illustratif_EN_small,
          fr: Logos.KAIROS_1_logo_illustratif_FR_small,
        })}
      />
    </div>
  );
}

export default function RegistrationMenu(): JSX.Element {
  return (
    <SuspenseLoader>
      <Component />
    </SuspenseLoader>
  );
}
