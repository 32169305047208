import { useEffect, useState } from "react";

type WindowDimensions = {
  width: number;
  height: number;
  breakpoints: Breakpoints;
};

type Breakpoints = {
  sm: boolean;
  md: boolean;
  lg: boolean;
};

/**
 * Hook to keep track of window dimensions, can be used to set breakpoints in code.
 */
const useWindowDimensions = (): WindowDimensions => {
  const BREAKPOINTS = {
    sm: 576,
    md: 768,
    lg: 992,
  };

  const getWindowDimensions = (): WindowDimensions => {
    const { innerWidth: width, innerHeight: height } = window;

    return {
      width,
      height,
      breakpoints: {
        sm: width > BREAKPOINTS.sm,
        md: width > BREAKPOINTS.md,
        lg: width > BREAKPOINTS.lg,
      },
    };
  };

  const [windowDimensions, setWindowDimensions] = useState<WindowDimensions>(
    getWindowDimensions()
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};

export default useWindowDimensions;
