import { BevelButton } from "components/inputs";
import { PlainCard } from "components/layout/cards";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const AlreadySubscribedAlert = (): JSX.Element => {
  const { t } = useTranslation(["webappBase"]);

  return (
    <PlainCard className="alert-emailconfirmation p-3">
      {/* Titre */}
      <h2 className="title text-left">{t("alerts.alreadysubscribed.title")}</h2>
      <div className="d-flex align-items-stretch">
        <div className="d-flex align-items-end flex-column flex-grow-1">
          <div className="text mb-2">
            <p>
              {t("alerts.alreadysubscribed.text")}&nbsp;
              <a
                href="mailto:support@kairosgame.com"
                target="_blank"
                rel="noreferrer"
              >
                support@kairosgame.com
              </a>
            </p>
          </div>
          <div className="button mt-auto ml-auto">
            <Link to="/app/settings">
              <BevelButton className="btn btn-primary">
                {t("alerts.alreadysubscribed.button")}
              </BevelButton>
            </Link>
          </div>
        </div>
      </div>
    </PlainCard>
  );
};

export default AlreadySubscribedAlert;
