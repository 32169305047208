import { UserCredential } from "firebase/auth";
import React, { useState } from "react";
import AuthError from "../commons/AuthError/AuthError";
import useRegistration from "../hooks/useRegistration/useRegistration";
import { EmailRegistrationArgs } from "../types";
import RegistrationPageComponent from "./RegistrationPageComponent";
import { useTranslation } from "react-i18next";

interface Props {
  onSuccess: (
    response: UserCredential,
    firstName?: string,
    lastName?: string
  ) => void;
  onFailure: (err: AuthError) => void;
  referrerId?: string;
}

const RegistrationPage = ({
  onSuccess,
  onFailure,
  referrerId,
}: Props): JSX.Element => {
  const { i18n } = useTranslation(["webappAuthentication"]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    actions: { registerByEmail, registerCompleteUserByEmail },
  } = useRegistration();

  const onSubmit = async (
    email: string,
    password: string,
    firstName?: string,
    lastName?: string
  ) => {
    setIsLoading(true);

    if (referrerId) {
      const data: EmailRegistrationArgs = {
        email: email,
        password: password,
        language: i18n.language,
        referrerId: referrerId,
        firstName,
        lastName,
      };
      registerCompleteUserByEmail(data)
        .then((response) => {
          onSuccess(response);
        })
        .catch((err: AuthError) => {
          onFailure(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      const data: EmailRegistrationArgs = {
        email: email,
        password: password,
        language: i18n.language,
        referrerId: referrerId,
      };
      registerByEmail(data)
        .then((response) => {
          onSuccess(response, firstName, lastName);
        })
        .catch((err: AuthError) => {
          onFailure(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <RegistrationPageComponent
      isLoading={isLoading}
      onSubmit={onSubmit}
      referral={!!referrerId}
    />
  );
};
export default RegistrationPage;
