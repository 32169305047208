import { SxProps, Theme } from "@mui/system";
import { SystemCssProperties } from "@mui/system";

const flexCenter: SxProps<Theme> = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const noScroll: SxProps<Theme> = {
  "&::-webkit-scrollbar": {
    display: "none",
  },
  "msOverflowStyle": "none" /* IE and Edge */,
  "scrollbarWidth": "none" /* Firefox */,
};

const square = (size: string): SystemCssProperties<Theme> => ({
  width: size,
  height: size,
});

const Layout = {
  flexCenter,
  noScroll,
  square,
};

export default Layout;
