import React, { FunctionComponent } from "react";
import { Badge } from "react-bootstrap";
import Dropdown from "../Dropdown/Dropdown";
import { ChildrenById, TasksToValidate } from "hooks/data/ChildContext";
import profileIcons from "assets/ProfileIcons";

interface ChildPickerProps {
  userDefinitions: ChildrenById;
  tasksToValidate: TasksToValidate;
  userId: string;
  setUserId: (id: string) => void;
}

const ChildPicker: FunctionComponent<ChildPickerProps> = ({
  userDefinitions,
  tasksToValidate,
  userId,
  setUserId,
}) => {
  return (
    <>
      <Dropdown
        className="child-picker w-100"
        buttonClasses="btn btn-primary w-100 d-flex align-items-center"
        toggle={
          <>
            <img
              alt=""
              src={profileIcons[userDefinitions[userId].icon]}
              className="profile-icon mr-3"
            />
            <b className="mr-2">{userDefinitions[userId].name}</b>
            <Badge variant="secondary" className="ml-auto mt-1">
              {tasksToValidate[userId].length}
            </Badge>
          </>
        }
        links={Object.keys(userDefinitions).map((user) => {
          return {
            onClick: () => setUserId(user),
            text: (
              <>
                <img
                  alt=""
                  src={profileIcons[userDefinitions[user].icon]}
                  className="profile-icon mr-3"
                />
                <b className="mr-2">{userDefinitions[user].name}</b>
                <Badge variant="secondary" className="ml-auto">
                  {tasksToValidate[user].length}
                </Badge>
              </>
            ),
          };
        })}
      />
    </>
  );
};

export default ChildPicker;
