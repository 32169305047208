import React from "react";
import { useTranslation } from "react-i18next";

//  Notifications.
import { store } from "react-notifications-component";
import {
  dangerNotification,
  successNotification,
} from "components/notifications/notification";

//  Components.
import { CardPage } from "components/layout/pages";
import { LogoCard } from "components/layout/cards";
import { SuspenseLoader } from "components/graphics";
import { AuthError, RecoverAccountPage } from "services/authentication";
import {
  PasswordResetRequested,
  useAnalytics,
} from "@neurosolutionsgroup/analytics";

const Component = () => {
  //  Localization.
  const { t } = useTranslation(["webappAuthentication", "webappBase"]);

  const { handleEvent } = useAnalytics();

  const onSuccess = () => {
    const event: PasswordResetRequested = {
      name: "Password Reset Requested",
    };

    handleEvent(event);

    store.addNotification(successNotification("", t("recoverAccount.success")));
  };

  const onFailure = (err: AuthError) => {
    store.addNotification(
      dangerNotification(
        t("webappBase:forms.errors.general"),
        t("recoverAccount.error")
      )
    );
  };

  return (
    <CardPage>
      <LogoCard className="bg-beige">
        <RecoverAccountPage onSuccess={onSuccess} onFailure={onFailure} />
      </LogoCard>
    </CardPage>
  );
};

export default function RecoverAccount(): JSX.Element {
  return (
    <SuspenseLoader>
      <Component />
    </SuspenseLoader>
  );
}
