import AvatarAssets from "assets/avatars";
import IconAssets from "assets/GeneralIcons";
import { BevelButton } from "components/inputs";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface SubscriptionSuccessAlertProps {
  open: boolean;
  onClose: VoidFunction;
}

const SubscriptionSuccessAlert: React.FC<SubscriptionSuccessAlertProps> = ({
  ...props
}) => {
  const { t } = useTranslation(["webappBilling"]);

  return props.open ? (
    <div className="subscription-success">
      <div className="subscription-success__dialog">
        <Row>
          <Col md={8}>
            <h2 className="text-center text-md-left">{t("success.title")}</h2>
            <p className="text-center text-md-left">{t("success.text")}</p>
          </Col>
          <Col md={4} className="d-flex justify-content-center">
            <img
              className="subscription-success__tag"
              src={IconAssets.GreenCheckmarkTag}
              alt=""
            />
          </Col>
        </Row>
        <BevelButton
          className="btn btn-primary-alt mt-1"
          onClick={() => props.onClose()}
        >
          {t("success.action")}
        </BevelButton>
      </div>
      <img
        src={AvatarAssets.GroupCelebrationL}
        className="subscription-success__avatars subscription-success__avatars--left"
        alt=""
      />
      <img
        src={AvatarAssets.GroupCelebrationR}
        className="subscription-success__avatars subscription-success__avatars--right"
        alt=""
      />
    </div>
  ) : null;
};

export default SubscriptionSuccessAlert;
