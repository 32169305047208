import { GamerChild, Routine, Task } from "@neurosolutionsgroup/models";
import { FrontEndRoutine } from "models/Types";

export type NormalizrResult = {
  entities: {
    tasks: { [key: string]: Task };
    children: { [key: string]: GamerChild };
    routines: { [key: string]: FrontEndRoutine };
  };
  result: {
    children: string[];
    routines: string[];
  };
};

export const parseAPIRoutineData = (playFabRoutines: Routine[]): Routine[] => {
  const routines: Routine[] = [];

  playFabRoutines.forEach((routine) => {
    routine.tasks.forEach((task) => {
      task.routine = routine.id;
    });

    routines.push(routine);
  });

  return routines;
};
