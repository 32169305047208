import { BevelButton } from "components/inputs";
import useUserProfile from "hooks/account/useUserProfile";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FirebaseAPI from "@neurosolutionsgroup/api-client";
import { useAuth } from "services/authentication";

const DeleteAccount = (): JSX.Element => {
  const { t } = useTranslation(["settings", "general"]);
  const {
    selectors: { subscriptionStatus },
  } = useUserProfile();
  const {
    actions: { invalidateSession },
  } = useAuth();

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openSubscriptionDeleteDialog, setOpenSubscriptionDeleteDialog] =
    useState(false);
  const [openSecondDeleteDialog, setOpenSecondDeleteDialog] = useState(false);

  const onDeleteAccount = () => {
    setOpenDeleteDialog(false);

    if (subscriptionStatus?.isSubscribed) {
      setOpenSubscriptionDeleteDialog(true);
    } else {
      setOpenSecondDeleteDialog(true);
    }
  };

  const onSubscriptionDeleteContinue = () => {
    setOpenSubscriptionDeleteDialog(false);

    setOpenSecondDeleteDialog(true);
  };

  const deleteUserInfo = async () => {
    console.log("Delete user info.");

    try {
      await FirebaseAPI.Account.deleteUserInfo();
    } finally {
      invalidateSession();
    }
  };

  return (
    <div>
      <BevelButton
        onClick={() => setOpenDeleteDialog(true)}
        className="btn btn-primary float-right"
      >
        {t("settings:delete")}
      </BevelButton>
      <Modal show={openDeleteDialog} onHide={() => setOpenDeleteDialog(false)}>
        <Modal.Header>{t("settings:deleteDialog.title")}</Modal.Header>
        <Modal.Body>{t("settings:deleteDialog.first.text")}</Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setOpenDeleteDialog(false)}>
            {t("general:actions.cancel")}
          </Button>
          <Button onClick={onDeleteAccount}>
            {t("general:actions.continue")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={openSubscriptionDeleteDialog}
        onHide={() => setOpenSubscriptionDeleteDialog(false)}
      >
        <Modal.Header>{t("settings:deleteDialog.title")}</Modal.Header>
        <Modal.Body>{t("settings:deleteDialog.subscription.text")}</Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setOpenSubscriptionDeleteDialog(false)}>
            {t("general:actions.cancel")}
          </Button>
          <Button onClick={onSubscriptionDeleteContinue}>
            {t("settings:deleteDialog.subscription.action")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={openSecondDeleteDialog}
        onHide={() => setOpenSecondDeleteDialog(false)}
      >
        <Modal.Header>{t("settings:deleteDialog.title")}</Modal.Header>
        <Modal.Body>{t("settings:deleteDialog.final.text")}</Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setOpenSecondDeleteDialog(false)}>
            {t("general:actions.cancel")}
          </Button>
          <Button onClick={deleteUserInfo}>
            {t("settings:deleteDialog.final.action")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DeleteAccount;
