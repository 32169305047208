import {
  FirestoreCollection,
  FirestoreUserDoc,
} from "@neurosolutionsgroup/models";
import { doc, getDoc, getFirestore, updateDoc } from "firebase/firestore";
import AuthenticationService from "services/AuthenticationTools";
import SHA256 from "crypto-js/sha256";

//  Update user contact email.
const updateContactEmail = async (email: string): Promise<void> => {
  const userId = AuthenticationService.getCurrentUser().uid;

  const db = getFirestore();

  const userDocRef = doc(db, FirestoreCollection.Users, userId);

  return updateDoc(userDocRef, {
    email: email,
  });
};

//  Update user profile language.
const updateProfileLanguage = async (language: string): Promise<void> => {
  const userId = AuthenticationService.getCurrentUser().uid;

  const db = getFirestore();

  const userDocRef = doc(db, FirestoreCollection.Users, userId);

  const userDoc = (await getDoc(userDocRef)).data() as FirestoreUserDoc;

  let masterSettings = userDoc.masterSettings as MasterSettings | null;

  if (!masterSettings) {
    masterSettings = {
      version: 0,
      settings: {
        language,
      },
    };
  }

  masterSettings.settings.language = language;

  return updateDoc(userDocRef, {
    masterSettings,
  });
};

interface MasterSettings {
  version: number;
  settings: {
    nip?: string;
    language?: string;
    FreeTrialExtended?: boolean;
    FreeTrialExtendedDate?: number;
  };
}

const updateParentPin = async (pin: string): Promise<void> => {
  const userId = AuthenticationService.getCurrentUser().uid;

  const db = getFirestore();

  const userDocRef = doc(db, FirestoreCollection.Users, userId);

  const userDoc = (await getDoc(userDocRef)).data() as FirestoreUserDoc;

  let masterSettings = userDoc.masterSettings as MasterSettings | null;

  const nipSHA256 = SHA256(pin).toString();

  if (!masterSettings) {
    masterSettings = {
      version: 0,
      settings: {
        nip: nipSHA256,
      },
    };
  }

  masterSettings.settings.nip = nipSHA256;

  return updateDoc(userDocRef, {
    masterSettings,
  });
};

const addExtendedFreeTrial = async (): Promise<void> => {
  const userId = AuthenticationService.getCurrentUser().uid;

  const db = getFirestore();

  const userDocRef = doc(db, FirestoreCollection.Users, userId);

  const userDoc = (await getDoc(userDocRef)).data() as FirestoreUserDoc;

  let masterSettings = userDoc.masterSettings as MasterSettings | null;

  if (!masterSettings) {
    masterSettings = {
      version: 0,
      settings: {
        FreeTrialExtended: true,
        FreeTrialExtendedDate: Math.round(Date.now() / 1000),
      },
    };
  }

  return updateDoc(userDocRef, {
    masterSettings,
  });
};

const Account = {
  addExtendedFreeTrial,
  updateContactEmail,
  updateParentPin,
  updateProfileLanguage,
};

export default Account;
