import pi_0 from "./pi_0.png";
import pi_1 from "./pi_1.png";
import pi_2 from "./pi_2.png";
import pi_3 from "./pi_3.png";
import pi_4 from "./pi_4.png";
import pi_5 from "./pi_5.png";

const profileIcons: Record<number, string> = {
  0: pi_0,
  1: pi_1,
  2: pi_2,
  3: pi_3,
  4: pi_4,
  5: pi_5,
};

export default profileIcons;
