import AvatarAssets from "assets/avatars";
import { BevelButton } from "components/inputs";
import useUserProfile from "hooks/account/useUserProfile";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const SubscriptionBanner: React.FC = () => {
  const ROOT_CLASS = "subscription-banner";

  const { t } = useTranslation(["webappDashboard"]);

  const history = useHistory();

  const {
    selectors: { subscriptionStatus, dataFetched },
  } = useUserProfile();

  return dataFetched && !subscriptionStatus?.isSubscribed ? (
    <div className={"sticky-top w-100 " + ROOT_CLASS}>
      <Container>
        <Row className="justify-content-center align-items-center">
          <Col className="d-none d-sm-block" lg={1} sm={2}>
            <img
              className={ROOT_CLASS + "__image"}
              src={AvatarAssets.RunningSM}
              alt="avatar"
            />
          </Col>
          <Col lg={8} md={7} sm={6} className="text-center text-sm-left">
            <span className={"d-none d-sm-inline " + ROOT_CLASS + "__text"}>
              {t("subscriptionBanner.continue")}
            </span>
          </Col>
          <Col md={3} sm={4}>
            <BevelButton
              className={"btn btn-primary-alt " + ROOT_CLASS + "__button"}
              onClick={() => history.push("/app/billing")}
            >
              {t("subscriptionBanner.action")}
            </BevelButton>
          </Col>
        </Row>
      </Container>
    </div>
  ) : null;
};

export default SubscriptionBanner;
