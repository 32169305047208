import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import AuthError, { AuthErrorCode } from "../../commons/AuthError/AuthError";
import { i18n } from "i18next";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { I18nextProvider } from "react-i18next";
import { Auth, onAuthStateChanged, User } from "firebase/auth";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { FirestoreCollection } from "@neurosolutionsgroup/models";

export interface AuthContextData {
  user: string | undefined;
  setUser: Dispatch<SetStateAction<string | undefined>>;
  tenant: string | undefined;
  setTenant: Dispatch<SetStateAction<string | undefined>>;
  invalidateSession: () => void;
  auth: Auth;
}

const AuthContext = React.createContext<AuthContextData | undefined>(undefined);

export const useAuthContext = (): AuthContextData => {
  const contextIsDefined = React.useContext(AuthContext);
  if (!contextIsDefined) {
    throw new AuthError(AuthErrorCode.ContextNotDefined);
  }
  return contextIsDefined;
};

interface AuthProviderProps {
  reCaptchaKey: string;
  i18n: i18n;
  authInstance: Auth;
}

export const AuthProvider = ({
  reCaptchaKey,
  i18n,
  authInstance,
  ...props
}: PropsWithChildren<AuthProviderProps>): JSX.Element => {
  const [user, setUser] = useState<string | undefined>(undefined);
  const [tenant, setTenant] = useState<string | undefined>(undefined);
  const [auth] = useState<Auth>(authInstance);

  useEffect(() => {
    if (auth) {
      onAuthStateChanged(auth, (user: User | null) => {
        setUser(user?.uid);

        if (user) {
          const db = getFirestore();

          const docRef = doc(db, FirestoreCollection.Users, user.uid);

          getDoc(docRef)
            .then((res) => {
              setTenant(res.data()?.tenantId);
            })
            .catch((err) => {
              console.log("Getting user information failed: " + err);
              //invalidateSession();
            });
        }
      });
    }
  }, [auth]);

  /**
   * Clear session information and localStorage.
   */
  const invalidateSession = () => {
    setUser(undefined);
  };

  return (
    <I18nextProvider i18n={i18n}>
      <GoogleReCaptchaProvider
        reCaptchaKey={reCaptchaKey}
        language={i18n.language}
      >
        <AuthContext.Provider
          value={{
            user,
            setUser,
            tenant,
            setTenant,
            invalidateSession,
            auth,
          }}
        >
          {props.children}
        </AuthContext.Provider>
      </GoogleReCaptchaProvider>
    </I18nextProvider>
  );
};
