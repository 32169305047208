import { schema } from "normalizr";

export const child = new schema.Entity("children");

export const task = new schema.Entity("tasks");

export const routine = new schema.Entity("routines", {
  tasks: [task],
  children: [child],
});
