import { getAuth, User } from "firebase/auth";

const getIdToken = async (): Promise<string> => {
  const auth = getAuth();

  return (
    auth.currentUser?.getIdToken() ??
    Promise.reject(new Error("No authentication found when getting id token."))
  );
};

const getCurrentUser = (): User => {
  const user = getAuth().currentUser;

  if (user) {
    return user;
  } else {
    throw new Error("Authenticated user not found.");
  }
};

interface AuthHeader {
  Authorization: string;
}

const getAuthHeader = async (): Promise<AuthHeader> => {
  const idToken = await getIdToken();

  return {
    Authorization: `Bearer ${idToken}`,
  };
};

const AuthenticationTools = {
  getAuthHeader,
  getCurrentUser,
  getIdToken,
};

export default AuthenticationTools;
