import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { BevelButton, PasswordInput } from "../../../../components/inputs";

interface Props {
  onSubmit: (password: string) => void;
  isLoading: boolean;
}

const ResetPasswordFormComponent = ({
  onSubmit,
  isLoading,
}: Props): JSX.Element => {
  const { t } = useTranslation(["webappAuthentication"]);

  const blockName = "reset-password";

  return (
    <Formik
      initialValues={{
        password: "",
      }}
      validationSchema={Yup.object({
        password: Yup.string()
          .min(6, t("fields.password.errors.length"))
          .max(100, t("fields.password.errors.length"))
          .required(t("fields.generic.errors.required")),
      })}
      onSubmit={(values) => {
        onSubmit(values.password);
      }}
    >
      <Form className={blockName + "__form"}>
        <PasswordInput
          label={t("fields.password.label")}
          name="password"
          dataTestId="resetpasswordform-password"
        />
        <div className={blockName + "__button-group"}>
          <BevelButton
            className="btn btn-primary w-100"
            type="submit"
            disabled={isLoading}
            onClick={() => true}
          >
            {t("pages.resetPassword.submit")}
          </BevelButton>
        </div>
      </Form>
    </Formik>
  );
};

export default ResetPasswordFormComponent;
