import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { BevelButton, TextInput } from "../../../../components/inputs";

interface Props {
  onSubmit: (email: string) => void;
  isLoading: boolean;
}

const RecoverAccountFormComponent = ({
  onSubmit,
  isLoading,
}: Props): JSX.Element => {
  const { t } = useTranslation(["webappAuthentication"]);

  const blockName = "recover-account";

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={Yup.object({
        email: Yup.string()
          .email(t("fields.email.errors.invalid"))
          .required(t("fields.generic.errors.required")),
      })}
      onSubmit={async (values) => {
        onSubmit(values.email);
      }}
    >
      <Form className={blockName + "__form"}>
        <TextInput
          label={t("fields.email.label")}
          name="email"
          type="email"
          dataTestId="recoveraccountform-email"
        />
        <div className={blockName + "__button-group"}>
          <BevelButton
            className="btn btn-primary w-100"
            type="submit"
            disabled={isLoading}
            onClick={() => true}
          >
            {t("pages.recoverAccount.submit")}
          </BevelButton>
        </div>
      </Form>
    </Formik>
  );
};

export default RecoverAccountFormComponent;
