import React, { useState } from "react";
import { useTranslation } from "react-i18next";

//  Features.
import PinReset from "./PinReset";

//  Components.
import { Col, Row } from "react-bootstrap";
import { SuspenseLoader } from "components/graphics";
import BevelButton from "components/inputs/BevelButton";

//  Notifications.
import { store } from "react-notifications-component";
import {
  successNotification,
  dangerNotification,
} from "components/notifications";
import { PlainCard } from "components/layout/cards";
import { useRecovery } from "services/authentication";
import DeleteAccount from "./DeleteAccount";
import {
  PasswordResetRequested,
  useAnalytics,
} from "@neurosolutionsgroup/analytics";

const Component = () => {
  const { t } = useTranslation(["webappSettings", "webappBase", "settings"]);

  const [isLoading, setIsLoading] = useState(false);

  const {
    actions: { requestPasswordReset },
  } = useRecovery();

  const { handleEvent } = useAnalytics();

  const resetPasswordRequestHandler = async () => {
    setIsLoading(true);
    try {
      await requestPasswordReset();

      const event: PasswordResetRequested = {
        name: "Password Reset Requested",
      };

      handleEvent(event);

      store.addNotification(successNotification("", t("auth.email.success")));
    } catch (err) {
      store.addNotification(
        dangerNotification(
          t("webappBase:forms.errors.title"),
          t("webappBase:forms.errors.general") +
            ": " +
            t(
              "webappBase:forms.errors." +
                (err instanceof Error ? err.message : "unknown")
            ),
          false
        )
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="mt-3">
      <h2>{t("auth.title")}</h2>
      <Row className="justify-content-center">
        <Col md={6} className="mt-3">
          <PlainCard className="p-3" outerClasses="h-100">
            <div className="d-flex flex-column h-100">
              <h3>{t("auth.email.title")}</h3>

              <p className="text-justify mb-2">{t("auth.email.message")}</p>

              <div className="mt-auto align-self-end">
                <BevelButton
                  disabled={isLoading}
                  className="btn btn-primary"
                  onClick={() => resetPasswordRequestHandler()}
                >
                  {t("auth.email.resetpassword")} <i className="icon-arrow"></i>
                </BevelButton>
              </div>
            </div>
          </PlainCard>
        </Col>
        <Col md={6} className="mt-3">
          <PlainCard className="p-3" outerClasses="h-100">
            <h3>{t("auth.inapp.title")}</h3>

            <p>{t("auth.inapp.pin.message")}</p>

            <div className="mt-2">
              <PinReset />
            </div>
          </PlainCard>
        </Col>
        <Col md={6} className="mt-3">
          <PlainCard className="p-3" outerClasses="h-100">
            <h3>{t("settings:delete")}</h3>

            <DeleteAccount />
          </PlainCard>
        </Col>
      </Row>
    </div>
  );
};

export default function SecuritySettings(): JSX.Element {
  return (
    <SuspenseLoader>
      <Component />
    </SuspenseLoader>
  );
}
