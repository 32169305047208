import { Loader } from "@neurosolutionsgroup/components";
import React from "react";
import RecaptchaMessage from "../components/recaptcha/RecaptchaMessage";
import RegistrationFormComponent from "./RegistrationFormComponent";
import { useTranslation } from "react-i18next";

interface Props {
  isLoading: boolean;
  onSubmit: (
    email: string,
    password: string,
    firstName?: string,
    lastName?: string
  ) => void;
  referral: boolean;
}

const RegistrationPageComponent = ({
  isLoading,
  onSubmit,
  referral,
}: Props): JSX.Element => {
  const { t } = useTranslation(["webappAuthentication"]);

  const blockName = "registration";

  return (
    <div className={blockName}>
      {isLoading ? <Loader /> : null}
      <div className={blockName + "__body"}>
        <h2 className={blockName + "__title"}>
          {t("pages.registration.title")}
        </h2>
        <RegistrationFormComponent
          isSubmitEnabled={!isLoading}
          onSubmit={onSubmit}
          referral={referral}
        />

        <RecaptchaMessage />
      </div>
    </div>
  );
};

export default RegistrationPageComponent;
