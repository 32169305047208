import React, { useState } from "react";
import AuthError from "../commons/AuthError/AuthError";
import useRegistration from "../hooks/useRegistration/useRegistration";
import CompleteRegistrationPageComponent from "./CompleteRegistrationPageComponent";

interface Props {
  onSuccess: (firstName: string, lastName: string) => void;
  onFailure: (err: AuthError) => void;
}

const CompleteRegistrationPage = ({
  onSuccess,
  onFailure,
}: Props): JSX.Element => {
  const {
    actions: { completeRegistration },
  } = useRegistration();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = (firstName: string, lastName: string, city: string) => {
    const data = {
      firstName: firstName,
      lastName: lastName,
      city: city,
    };

    completeRegistration(data)
      .then((respond) => {
        if (respond) {
          onSuccess(firstName, lastName);
        }
      })
      .catch((err: AuthError) => {
        onFailure(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <CompleteRegistrationPageComponent
      isLoading={isLoading}
      onSubmit={(firstName, lastName, city) =>
        onSubmit(firstName, lastName, city)
      }
    />
  );
};

export default CompleteRegistrationPage;
