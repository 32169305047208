import React, { useEffect, useState } from "react";
import { useHistory, Link, NavLink } from "react-router-dom";

import { useTranslation } from "react-i18next";
import useWindowDimensions from "hooks/useWindowDimensions";
import useUserProfile from "hooks/account/useUserProfile";

import { Navbar, Nav, Container, NavItem } from "react-bootstrap";
import { SuspenseLoader } from "components/graphics/SuspenseLoader";
import Dropdown, { DropdownLink } from "components/inputs/Dropdown/Dropdown";
import { BevelButton } from "components/inputs";
import { useAuth } from "services/authentication";
import LanguagePicker from "./LanguagePicker";
import kairosLogo from "assets/Logos";

const Component = () => {
  const [expanded, setExpanded] = useState(false);

  //  Language management.
  const { t } = useTranslation(["webappBase"]);

  const windowDimensions = useWindowDimensions();

  const {
    selectors: { dataFetched, subscriptionStatus },
  } = useUserProfile();

  const {
    actions: { invalidateSession },
  } = useAuth();

  const history = useHistory();

  const logout = () => {
    invalidateSession();
  };

  const dropdownLinks: DropdownLink[] = [
    {
      onClick: () => history.push("/app/settings"),
      text: <span>{t("nav.settings")}</span>,
    },
    {
      onClick: () => window.open("https://support.kairosgame.com", "_blank"),
      text: (
        <span>
          {t("nav.support")} &nbsp; <i className="fa fa-external-link"></i>
        </span>
      ),
    },
    {
      onClick: () => logout(),
      text: <span>{t("nav.logout")}</span>,
    },
  ];

  useEffect(() => {
    setExpanded(false);
  }, [history.location]);

  return (
    <Navbar
      bg="none"
      className="custom-navbar app"
      expand="lg"
      expanded={expanded}
      onToggle={setExpanded}
    >
      <Container>
        <Navbar.Brand>
          <Link to="/app/dashboard">
            <img
              className="header-logo-simple"
              alt="Kairos"
              src={kairosLogo.KAIROS_5_logo_simple_seul_blanc_small}
            />
          </Link>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="navbar-nav" className="btn btn-primary" />

        <Navbar.Collapse id="navbar-nav">
          <Nav className="ml-auto float-right">
            <NavItem>
              <Nav.Link
                as={NavLink}
                to="/app/dashboard"
                activeClassName="selected"
              >
                {t("nav.dashboard")}
              </Nav.Link>
            </NavItem>
            {windowDimensions.breakpoints.lg ? (
              <>
                {dataFetched && !subscriptionStatus?.isSubscribed ? (
                  <BevelButton
                    className="btn btn-primary-alt btn-nav ml-2 mb-2 float-right"
                    onClick={() => history.push("/app/billing")}
                  >
                    {t("nav.subscribe")}
                  </BevelButton>
                ) : null}
                <Dropdown
                  className="ml-2"
                  toggle={
                    <span>
                      <i className="icon-cog"></i>
                    </span>
                  }
                  links={dropdownLinks}
                  wrap={false}
                />
              </>
            ) : (
              <>
                <NavItem>
                  <Nav.Link
                    as={NavLink}
                    to="/app/settings"
                    activeClassName="selected"
                  >
                    {t("nav.settings")}
                  </Nav.Link>
                </NavItem>
                <NavItem>
                  <Nav.Link
                    as={NavLink}
                    to="https://support.kairosgame.com"
                    target="_blank"
                    activeClassName="selected"
                  >
                    {t("nav.support")} &nbsp;{" "}
                    <i className="fa fa-external-link"></i>
                  </Nav.Link>
                </NavItem>
                <NavItem>
                  <Nav.Link>
                    <span onClick={() => logout()}>{t("nav.logout")}</span>
                  </Nav.Link>
                </NavItem>
                {dataFetched && !subscriptionStatus?.isSubscribed ? (
                  <BevelButton
                    className="btn btn-primary-alt btn-nav mb-2 float-right"
                    onClick={() => history.push("/app/billing")}
                  >
                    {t("nav.subscribe")}
                  </BevelButton>
                ) : null}
              </>
            )}
            <LanguagePicker />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default function AppHeader(): JSX.Element {
  return (
    <SuspenseLoader>
      <Component />
    </SuspenseLoader>
  );
}
