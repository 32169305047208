import React from "react";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import { useLocation, Redirect, useHistory } from "react-router-dom";

import { AuthError, ResetPasswordPage } from "services/authentication";

//  Notifications.
import { store } from "react-notifications-component";
import {
  dangerNotification,
  successNotification,
} from "components/notifications/notification";

//  Components.
import { LogoCard } from "components/layout/cards";
import { CardPage } from "components/layout/pages";
import { SuspenseLoader } from "components/graphics";
import { PasswordReset, useAnalytics } from "@neurosolutionsgroup/analytics";

const Component = () => {
  //  Localization.
  const { t } = useTranslation(["webappAuthentication", "webappBase"]);

  //  Get params.
  const url = useLocation().search;
  const params = queryString.parse(url);

  const history = useHistory();

  const { handleEvent } = useAnalytics();

  const onSuccess = () => {
    const event: PasswordReset = {
      name: "Password Reset",
    };

    handleEvent(event);

    store.addNotification(
      successNotification("", t("passwordrecovery.success"), false)
    );

    history.push("/account/login");
  };

  const onFailure = (err: AuthError) => {
    store.addNotification(
      dangerNotification(
        t("webappBase:forms.errors.general"),
        t("webappBase:forms.errors." + err.code)
      )
    );
  };

  return (
    <CardPage>
      {params.token ? null : <Redirect to="/forgottenpassword" />}
      <LogoCard className="bg-beige">
        <ResetPasswordPage
          token={params.token?.toString() ?? ""}
          onSuccess={onSuccess}
          onFailure={onFailure}
        />
      </LogoCard>
    </CardPage>
  );
};

export default function ResetPassword(): JSX.Element {
  return (
    <SuspenseLoader>
      <Component />
    </SuspenseLoader>
  );
}
