import { useAuth } from "services/authentication";
import React, { FunctionComponent } from "react";
import { Route, Redirect, RouteProps, useLocation } from "react-router-dom";

const ProtectedRoute: FunctionComponent<RouteProps> = ({
  component: Component,
  ...rest
}) => {
  const location = useLocation();
  const urlParams = location.search;
  const urlPath = location.pathname;

  const {
    actions: { isAuthenticated },
  } = useAuth();

  if (!Component) return null;

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/account/login",
              state: { from: props.location },
              search: urlParams + "&redirect=" + urlPath + location.hash ?? "",
            }}
          />
        )
      }
    />
  );
};

export default ProtectedRoute;
