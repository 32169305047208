import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { BevelButton, PasswordInput, TextInput } from "components/inputs";

interface Props {
  autoCheckout?: boolean;
  isLoading: boolean;
  onSubmit: (email: string, password: string) => void;
  passwordLink?: string;
  email?: string;
}

const LoginFormComponent = ({
  autoCheckout = false,
  isLoading,
  onSubmit,
  passwordLink,
  email,
}: Props): JSX.Element => {
  const { t } = useTranslation(["webappAuthentication"]);

  const blockName = "login";

  return (
    <Formik
      initialValues={{
        email: email ?? "",
        password: "",
      }}
      validationSchema={Yup.object({
        email: Yup.string()
          .email(t("fields.email.errors.invalid"))
          .required(t("fields.generic.errors.required")),
        password: Yup.string()
          .min(6, t("fields.password.errors.length"))
          .max(100, t("fields.password.errors.length"))
          .required(t("fields.generic.errors.required")),
      })}
      onSubmit={async (values) => {
        onSubmit(values.email, values.password);
      }}
    >
      <Form className={blockName + "__form"}>
        <TextInput label={t("fields.email.label")} name="email" type="email" />
        <PasswordInput label={t("fields.password.label")} name="password" />
        {passwordLink ? (
          <a
            className={blockName + "__forgotten-password-link"}
            href={passwordLink}
          >
            {t("pages.login.passwordForgotten")}
          </a>
        ) : null}

        <div className={blockName + "__button-group"}>
          <BevelButton
            className="btn btn-primary w-100"
            type="submit"
            disabled={isLoading}
            onClick={() => true}
          >
            {autoCheckout
              ? t("pages.login.checkout.submit")
              : t("pages.login.submit")}
          </BevelButton>
        </div>
      </Form>
    </Formik>
  );
};

export default LoginFormComponent;
