import React, { PropsWithChildren, useRef } from "react";
import clsx from "clsx";
import { LogoCard } from "components/layout/cards";
import { useTranslation } from "react-i18next";
import { BevelButton } from "components/inputs";

interface ReferredRegistrationProps {
  referrerName?: string;
}

const ReferredRegistration = ({
  referrerName,
  ...props
}: PropsWithChildren<ReferredRegistrationProps>): JSX.Element => {
  const ROOT_CLASS = "referred-registration-page";

  const { t } = useTranslation("webappBilling");

  const topRef = useRef<HTMLDivElement>(null);

  return (
    <div className={clsx(ROOT_CLASS)}>
      <div
        className={clsx(ROOT_CLASS + "__registration", "row", "container")}
        ref={topRef}
      >
        <div className={clsx(ROOT_CLASS + "__registration-text", "col col-md")}>
          <h1>
            {t("referral.registration.registration.title") +
              (referrerName ?? "ton ami")}
            !
          </h1>
          <p>{t("referral.registration.registration.subtitle")}</p>
        </div>
        <div className={clsx(ROOT_CLASS + "__registration-form", "col col-md")}>
          <img
            className="affiliate_form_kairos"
            alt="Kairos Logo"
            src="https://kairosgame.com/media/1721/kairos-thumbs-up.png"
          />
          <LogoCard fullWidth={true} className="bg-beige">
            {props.children}
          </LogoCard>
        </div>
      </div>
      <div className={clsx(ROOT_CLASS + "__information")}>
        <svg
          viewBox="0 0 1440 432"
          id="icon__bg__banner-hero-b"
          xmlns="http://www.w3.org/2000/svg"
          className="section__bottom js-svg"
        >
          <path fill="#EF4044" d="M0 0h1440v255.1H0z"></path>
          <path
            opacity=".5"
            fill="#ECE9CB"
            d="M119.9 136.1l-9.9 3-4 9.9 5 9.4 10.2 1.7 7.7-7.2-.6-10.7-8.4-6.1z"
          ></path>
          <path
            fill="#E03D40"
            d="M375.4 89.7l8.2 3.3 7.9-4.1 1.5-8.4-5.9-6.5-8.9.4-5.3 7 2.5 8.3z"
          ></path>
          <path
            fill="#DED99E"
            d="M1440 178.8l-443.3 35.3-604.2-77.6L0 207v91.8h1440v-120z"
          ></path>
          <path
            fill="#ECE9CB"
            d="M392.5 136.4L0 277.6V207zM996.7 214.1l443.3 35.3v-70.6z"
          ></path>
          <path
            fill="#F4806F"
            d="M846 38.1l5-14.4-8.1-12.9-15.1-1.7L817 19.8l1.7 15.1 12.9 8.1 14.4-4.9z"
          ></path>
          <path
            fill="#EF4044"
            d="M200 358l-20.8-18.9-27.6 4.5-13.6 24.5 10.5 26 26.8 8 22.9-16.1 1.8-28z"
          ></path>
          <path
            fill="#FFF"
            d="M146 354.1l25.1 21L187 364l-12.9 2.9-28.1-12.8z"
          ></path>
          <path
            fill="#EF4044"
            d="M1058.5 417l11.9 5.1 11.4-6.2 2.3-12.9-8.5-9.9-12.9.6-7.6 10.6 3.4 12.7z"
          ></path>
          <path
            fill="#DED99E"
            d="M40.5 426.1l8.3-4 2.1-8.9-5.8-7.2h-9.3l-5.8 7.2 2.1 8.9 8.4 4z"
          ></path>
          <path
            fill="#FFF"
            d="M1440 254.6l-200 26L868.2 229 77.7 296.7 0 276.4v50.7h1440v-72.5z"
          ></path>
          <path
            fill="#ECE9CB"
            d="M563 319.1L78 296.6 868 229l-305 90.1z"
          ></path>
          <path
            fill="#DED99E"
            d="M840 347.1l14.9 21.4 25.8 1.5 17.3-19.5-4.3-25.8-22.6-12.8-23.9 9.9-7.2 25.3z"
          ></path>
          <path
            fill="#ECE9CB"
            d="M1326.5 322.7l6.2 4.4 7.3-2.2 3-7-3.7-6.6-7.6-1.2-5.7 5zM1240 280.7l200 8.4v-34z"
          ></path>
        </svg>
        <div className={clsx("row", "container")}>
          <div className="col col-sm-12">
            <h4>{t("referral.registration.information.title")}</h4>
            <h3>{t("referral.registration.information.subtitle")}</h3>
          </div>
          <div className="col col-sm-4">
            <img
              className="affiliate-images"
              src="https://kairosgame.com/media/1748/kairos-refere-un-ami.png"
              alt=""
            />
            <p>
              <b>
                {t("referral.registration.information.text.discount.title")}
              </b>
            </p>
            <p>{t("referral.registration.information.text.discount.text")}</p>
          </div>

          <div className="col col-sm-4">
            <img
              className="affiliate-images"
              src="https://kairosgame.com/media/1746/kairos-costumes-amusants.png"
              alt=""
            />

            <p>
              <b>{t("referral.registration.information.text.reward.title")}</b>
            </p>
            <p>{t("referral.registration.information.text.reward.text")}</p>
          </div>

          <div className="col col-sm-4">
            <img
              className="affiliate-images"
              src="https://kairosgame.com/media/1747/kairos-recoit-des-recompenses.png"
              alt=""
            />
            <p>
              <b>{t("referral.registration.information.text.routine.title")}</b>
            </p>
            <p>{t("referral.registration.information.text.routine.text")}</p>
          </div>
          <div className="col col-sm-12 d-flex justify-content-center">
            <div className="create-account-button">
              <BevelButton
                className="btn btn-primary w-100"
                onClick={() => {
                  topRef.current?.scrollIntoView({ behavior: "smooth" });
                }}
              >
                {t("referral.registration.information.action")}
              </BevelButton>
            </div>
          </div>
          <svg
            viewBox="0 0 1440 84"
            id="icon__bg__section-t"
            xmlns="http://www.w3.org/2000/svg"
            className="section__top js-svg"
          >
            <path
              className="dark"
              fill="#3B6466"
              d="M0 6.5l388.2 42.1L805.2 0l204.2 48.6L1327.1 0 1440 26.9V84H0V6.5z"
            ></path>
            <path
              className="light"
              fill-rule="evenodd"
              clip-rule="evenodd"
              fill="#629D9B"
              d="M1327.1 0L1440 26.9v29.8L1327.1 0zM805.2 0L625.5 70.7 388.2 48.6 0 82.5l635.3-10.9-5.5-.5 379.6-22.6L805.2 0zm-636 24.8L0 82.5v-76l169.2 18.3zM1440 65.5l-430.6-17 172.2-26.3L1440 65.5z"
            ></path>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default ReferredRegistration;
