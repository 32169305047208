import { Loader } from "@neurosolutionsgroup/components";
import React from "react";
import RecaptchaMessage from "../../components/recaptcha/RecaptchaMessage";
import RecoverAccountFormComponent from "./RecoverAccountFormComponent";
import { useTranslation } from "react-i18next";

interface Props {
  onSubmit: (email: string) => void;
  isLoading: boolean;
}

const RecoverAccountPageComponent = ({
  onSubmit,
  isLoading,
}: Props): JSX.Element => {
  const { t } = useTranslation(["webappAuthentication"]);

  const blockName = "recover-account";

  return (
    <div className={blockName}>
      {isLoading ? <Loader /> : null}
      <div className={blockName + "__body"}>
        <h1 className={blockName + "__title"}>
          {t("pages.recoverAccount.title")}
        </h1>
        <hr />
        <p>{t("pages.recoverAccount.subtitle")}</p>
        <p>{t("pages.recoverAccount.text")}</p>
        <hr />
        <RecoverAccountFormComponent
          onSubmit={onSubmit}
          isLoading={isLoading}
        />

        <RecaptchaMessage />
      </div>
    </div>
  );
};

export default RecoverAccountPageComponent;
