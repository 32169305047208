import React from "react";

//  Features.
import AccountSettings from "./account/AccountSettings";
import SecuritySettings from "./security/SecuritySettings";

//  Components.
import { SuspenseLoader } from "components/graphics";
import Billing from "./billing/Billing";

const Component = () => {
  return (
    <div>
      <AccountSettings />

      <SecuritySettings />

      <Billing />
    </div>
  );
};

export default function ProfileSettings(): JSX.Element {
  return (
    <SuspenseLoader>
      <Component />
    </SuspenseLoader>
  );
}
