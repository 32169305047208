import { Task } from "@neurosolutionsgroup/models";
import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useState,
} from "react";

export type TasksById = {
  [key: string]: Task;
};

export interface TaskContextData {
  tasksById: TasksById;
  setTasksById: Dispatch<SetStateAction<TasksById>>;
}

const TaskContext = React.createContext<TaskContextData>({
  tasksById: {},
  setTasksById: () => {},
});

export const TaskProvider = (props: PropsWithChildren): JSX.Element => {
  const [tasksById, setTasksById] = useState<TasksById>({});

  return (
    <TaskContext.Provider value={{ tasksById, setTasksById }}>
      {props.children}
    </TaskContext.Provider>
  );
};

export default TaskContext;
