import { Language } from "@neurosolutionsgroup/models";
import clsx from "clsx";
import useLanguage from "hooks/useLanguage";
import React, { useMemo } from "react";

const LanguagePicker = (): JSX.Element | null => {
  const { language, setLanguage, languages } = useLanguage();

  const changeLanguage = (lng: Language) => {
    setLanguage(lng);
  };

  const otherLanguages: Language[] = useMemo(() => {
    return languages.filter((l) => l !== language);
  }, [languages, language]);

  return otherLanguages.length > 0 ? (
    <div className="d-flex justify-content-end align-items-center ml-2">
      {otherLanguages
        .sort((a, b) => a.localeCompare(b))
        .map((lang) => (
          <div
            className={clsx("language-toggler", "color-white", "px-1")}
            onClick={() => changeLanguage(lang)}
            key={lang}
          >
            <b>{lang.toUpperCase()}</b>
          </div>
        ))}
    </div>
  ) : null;
};

export default LanguagePicker;
