import { HookResult } from "hooks/HookResult";
import { useContext } from "react";
import TaskContext, { TasksById } from "./TaskContext";
import { useChildContext } from "./ChildContext";
import FirebaseAPI from "@neurosolutionsgroup/api-client";
import {
  GetAllTaskHistoriesForChildBody,
  TaskHistory,
} from "@neurosolutionsgroup/models";
import { v4 } from "uuid";

export interface UseTasksSelectors {
  tasksById: TasksById;
}

export interface UseTasksActions {
  validateTasks: (
    taskHistory: TaskHistory[],
    gamerChildId: string
  ) => Promise<void>;
  setTaskStatus: (args: SetTaskStatusArgs) => void;
  getMissingHistories: (userId: string) => Promise<void>;
}

type SetTaskStatusArgs = {
  userId: string;
  taskIndex: number;
  status: boolean | null;
};

const useTasks = (): HookResult<UseTasksSelectors, UseTasksActions> => {
  const { tasksById } = useContext(TaskContext);
  const { childrenById, setChildrenById, setTasksToValidate } =
    useChildContext();

  const setTaskStatus = (args: SetTaskStatusArgs) => {
    setTasksToValidate((prevState) => {
      return {
        ...prevState,
        [args.userId]: prevState[args.userId].map((task, index) => {
          if (index === args.taskIndex) {
            return {
              ...task,
              status: args.status,
              validated: true,
            };
          } else {
            return task;
          }
        }),
      };
    });
  };

  const validateTasks = async (
    taskHistory: TaskHistory[],
    gamerChildId: string
  ): Promise<void> => {
    await FirebaseAPI.Child.validateTasks({
      history: taskHistory.map((th) => ({
        ...th,
        id: th.id ?? v4(),
        childStatus: th.childStatus ?? undefined,
        status: th.status ?? undefined,
        confirmTime: th.confirmTime ?? undefined,
        durationSeconds: th.durationSeconds ?? undefined,
      })),
      gamerChildId,
      version: 0,
    });

    const newHistories = childrenById[gamerChildId].history.map((h) => {
      const newTaskIndex = taskHistory.findIndex((th) => th.id === h.id);

      if (newTaskIndex !== -1) {
        return taskHistory[newTaskIndex];
      } else {
        return h;
      }
    });

    setChildrenById((current) => {
      const clone = { ...current };

      clone[gamerChildId].history = newHistories;

      return clone;
    });

    setTasksToValidate((current) => {
      const clone = { ...current };

      clone[gamerChildId] = newHistories
        .filter((h) => h.confirmTime === null)
        .sort((a, b) => b.dueTime - a.dueTime)
        .map((h) => ({ ...h, validated: false }));

      return clone;
    });
  };

  const getMissingHistories = async (gamerChildId: string) => {
    const deviceUtcOffsetMin = -new Date().getTimezoneOffset();

    const data: GetAllTaskHistoriesForChildBody = {
      deviceUtcOffsetMin,
      maximumHistoryDays: 7,
      gamerChildId,
    };

    return FirebaseAPI.Child.getMissingHistories(data).then((response) => {
      const filteredTasks: TaskHistory[] = [];

      response
        .filter((t) => (t !== null ? t.confirmTime === null : false))
        .forEach((task) => {
          filteredTasks.push(task);
        });

      setTasksToValidate((prevState) => {
        return {
          ...prevState,
          [gamerChildId]: filteredTasks
            .filter((h) => h.confirmTime === null)
            .sort((a, b) => b.dueTime - a.dueTime)
            .map((h) => ({ ...h, validated: false })),
        };
      });
    });
  };

  return {
    selectors: {
      tasksById,
    },
    actions: {
      validateTasks,
      setTaskStatus,
      getMissingHistories,
    },
  };
};

export default useTasks;
